import { Fragment, useEffect, useState, KeyboardEvent } from 'react';
import { createPortal } from 'react-dom';
import { useInView } from 'react-intersection-observer';
import { AnimatePresence, MotionConfig } from 'framer-motion';
import { useFloating, offset, flip, shift, autoUpdate, Placement } from '@floating-ui/react';
import { Icon, IconProps, useWindowSize, MOBILE_MAX_SIZE, Button } from '@sendible/design-system';
import { Action, Backdrop, Children, Container, Content, Header, ListItem, Spacer } from './index.styles';

export interface DropdownOption {
  action: string;
  icon?: IconProps['name'];
  isDisabled?: boolean;
  isHighlighted?: boolean;
  label: string;
}

export interface DropdownProps extends Component {
  /**
   * List of options. Required.
   */
  options: DropdownOption[];
  /**
   * Dropdown opening position. Optional.
   */
  placement?: Placement;
  /**
   * Option select event emitter. Required.
   */
  select: (option: string, index: number) => void;
  /**
   * Visibiity event emitter. Optional.
   */
  visibilityChange?: (state: boolean) => void;
  /**
   * Set predefined width. Optional.
   */
  width?: string;
}

export const Dropdown = ({ children, options, placement = 'right-start', select, visibilityChange, width }: DropdownProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const { width: w } = useWindowSize();
  const { ref: inViewRef, inView } = useInView({
    threshold: 1,
    rootMargin: '-8px',
  });

  const { x, y, strategy, refs } = useFloating({
    open: isVisible,
    onOpenChange: setIsVisible,
    placement,
    middleware: [offset(8), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  const parentElementDoesNotHaveId = (element: HTMLUnknownElement, id: string) => {
    if (element.id === id) return true;

    let parent = element.parentElement;

    while (parent) {
      if (parent.id === id) return false;
      parent = parent.parentElement;
    }

    return true;
  };

  const handleKeyDown = (key: KeyboardEvent, index: number, listLength: number) => {
    key.preventDefault();

    if (key.code === 'ArrowUp' && index > 0) {
      const { previousSibling } = document.activeElement.parentElement;

      if (previousSibling.role === 'presentation') {
        previousSibling.previousSibling.firstChild.focus();
      } else {
        previousSibling.firstChild.focus();
      }
    }
    if (key.code === 'ArrowDown' && index < listLength - 1) {
      const { nextSibling } = document.activeElement.parentElement;

      if (nextSibling.role === 'presentation') {
        nextSibling.nextSibling.firstChild.focus();
      } else {
        nextSibling.firstChild.focus();
      }
    }
    if (key.code === 'Escape') {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const target = e.target as HTMLElement;

      if (parentElementDoesNotHaveId(target, 'dd')) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mouseup', handleClickOutside);

    return () => document.removeEventListener('mouseup', handleClickOutside);
  }, [inViewRef]);

  useEffect(() => {
    if (isVisible && !inView) {
      setIsVisible(false);
    }
  }, [inView]);

  useEffect(() => {
    if (visibilityChange) visibilityChange(isVisible);
  }, [isVisible]);

  return (
    <>
      <Children
        onClick={() => setIsVisible((prev) => !prev)}
        ref={refs.setReference}
        className="dropdown-link"
        data-testid="sidebar-dropdown-link"
      >
        {children}
      </Children>
      {isVisible &&
        createPortal(
          <>
            <AnimatePresence>
              <MotionConfig transition={{ duration: 0.33, ease: 'easeInOut' }}>
                <Container
                  id="dd"
                  ref={refs.setFloating}
                  style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
                  $width={width}
                  initial={w <= MOBILE_MAX_SIZE ? { y: '100vh', opacity: 0 } : {}}
                  animate={w <= MOBILE_MAX_SIZE ? { y: 0, opacity: 1 } : {}}
                  exit={w <= MOBILE_MAX_SIZE ? { y: '100vh', opacity: 0 } : {}}
                >
                  {w <= MOBILE_MAX_SIZE && (
                    <Header>
                      <Button
                        onClick={() => setIsVisible(false)}
                        icon="close_lg"
                        size={16}
                      />
                    </Header>
                  )}
                  <Content ref={inViewRef}>
                    {options.map(({ action, icon, isDisabled, isHighlighted, label }, index) => (
                      <Fragment key={`dropdown-list-${index}`}>
                        {isHighlighted && (
                          <Spacer
                            role="presentation"
                            tabIndex={-1}
                            key={`dropdown-spacer-${index}`}
                          />
                        )}
                        <ListItem
                          key={`dropdown-item-${index}`}
                          className="dd-item"
                          onKeyDown={(event: KeyboardEvent<HTMLElement>) => {
                            handleKeyDown(event as KeyboardEvent, index, options.length);
                          }}
                        >
                          <Action
                            autoFocus={index === 0}
                            className="dd-action-button"
                            disabled={isDisabled}
                            data-testid={`sidebar-dropdown-item-link-${index}`}
                            onClick={() => {
                              select(action, index);
                              setIsVisible(false);
                            }}
                          >
                            {icon && <Icon name={icon} />}
                            {label}
                          </Action>
                        </ListItem>
                      </Fragment>
                    ))}
                  </Content>
                </Container>
              </MotionConfig>
            </AnimatePresence>
            <AnimatePresence>
              <MotionConfig transition={{ duration: 0.33, ease: 'easeInOut' }}>
                <Backdrop
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              </MotionConfig>
            </AnimatePresence>
          </>,
          document.getElementById('dropdown-root') as HTMLElement
        )}
    </>
  );
};
