'use client';

import { createPortal } from 'react-dom';
import CreativeEditorSDK, { UserInterfaceElements } from '@cesdk/cesdk-js';
import { MimeType } from '@cesdk/engine';
import { useEffect, useState } from 'react';
import { useToastNotificationContext } from '@sendible/design-system/src';
import { NotificationTypeEnum } from '@sendible/design-system/src/components/molecules/Toast';
import { CeSDKWrapper } from './index.styles';

import { initPhotoEditorUIConfig } from './PhotoEditor/PhotoEditorUIConfig';
import CreativeEditor, { useConfig, useConfigure } from './PhotoEditor/lib/CreativeEditor';
import { EDITOR_EXPORT, EDITOR_CLOSE } from '.';

let _instance: CreativeEditorSDK;

type EditorProps = {
  fileUrl: null | string;
  serviceSet: string;
  hasPinterest: boolean;
  resetState: () => void;
};

const Editor = ({ fileUrl, serviceSet, hasPinterest, resetState }: EditorProps) => {
  const [hasExported, setHasExported] = useState(false);
  const { showToastNotification } = useToastNotificationContext();

  useEffect(() => {
    document.body.style.margin = '0';

    window.pendo.track('Editor Open', { fileType: 'image' });
  }, []);

  const config = useConfig(
    () => ({
      role: 'Adopter',
      theme: 'dark',
      license: 'VHjEPgPLBuDp-hnJmlQe-UAEu1GdyVUXuNAyIuvheFkzoxpkPdl4A1oKbCGgmjsl',
      ui: {
        elements: {
          blocks: {
            '//ly.img.ubq/page': {
              format: false,
              stroke: { show: false },
              manage: false,
            },
          },
          panels: {
            inspector: {
              show: true,
              position: UserInterfaceElements.PanelPosition.Left,
              floating: false,
            },
            settings: false,
          },
          libraries: {
            replace: {
              floating: false,
              autoClose: true,
            },
            insert: {
              autoClose: true,
              floating: false,
            },
          },
          navigation: {
            title: 'Photo Editor',
            action: {
              custom: [
                {
                  callback: async () => {
                    if (!hasExported) {
                      try {
                        setHasExported(true);

                        const scene = _instance.engine.scene.get();
                        const mimeType = 'image/jpeg';
                        const options = { jpegQuality: 0.9 };
                        const blob = await _instance.engine.block.export(scene as number, mimeType as MimeType, options);

                        if (blob) {
                          window.pendo.track('Editor Export', { fileType: 'image' });
                          window.parent.postMessage({ event: EDITOR_EXPORT, blob }, '*');
                          resetState();
                        } else {
                          showToastNotification({
                            notificationText: 'There was an issue saving your image. Please try again',
                            type: NotificationTypeEnum.Error,
                            showCloseButton: true,
                          });
                        }

                        setHasExported(false);
                      } catch (error) {
                        showToastNotification({
                          notificationText: 'There was an issue saving your image. Please try again',
                          type: NotificationTypeEnum.Error,
                          showCloseButton: true,
                        });
                        setHasExported(false);
                      }
                    }
                  },
                  label: 'Save',
                  iconName: 'save',
                },
              ],
              close: true,
            },
          },
        },
      },
      i18n: {
        en: {
          'component.fileOperation.exportImage': 'Export Image',
        },
      },
      callbacks: {
        onClose: () => {
          window.pendo.track('Editor Close', { fileType: 'image' });
          window.parent.postMessage({ event: EDITOR_CLOSE }, '*');
          resetState();
        },
      },
    }),
    []
  );

  let configure = null;

  configure = useConfigure(
    async (instance) => {
      await instance.addDefaultAssetSources();
      await instance.addDemoAssetSources({ sceneMode: 'Design' });

      // updated
      const cleanup = await initPhotoEditorUIConfig(instance, fileUrl as string, serviceSet, hasPinterest);

      _instance = instance;

      const page = instance.engine.scene.getCurrentPage();

      instance.engine.block.select(page!);
      await new Promise((resolve) => {
        setTimeout(resolve, 100);
      });
      instance.ui.openPanel('ly.img.page-crop');
      instance.engine.editor.setEditMode('Crop');

      return cleanup;
    },
    [fileUrl]
  );

  if (!fileUrl) {
    configure = undefined;
  }

  return (
    <div className="cesdkWrapperStyle">
      {createPortal(
        <CeSDKWrapper>
          <CreativeEditor
            className="cesdk-photo-editor cesdkStyle"
            style={{
              // Hide the inspector bar
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              '--ubq-InspectorBar-background': 'var(--ubq-canvas)',
            }}
            config={config}
            configure={configure}
          />
        </CeSDKWrapper>,
        document.getElementById('modal-root') as HTMLElement
      )}
    </div>
  );
};

export default Editor;
