import styled, { css } from 'styled-components';
import { SocialIcon } from '.';

export const ThumbBox = styled.div`
  ${({ theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.sm};
    box-shadow: inset 0 0 0 1px ${colors.brandDarkAlpha20};
    height: 2rem;
    margin-left: 0.25rem;
    position: relative;
    width: 2rem;
    overflow: visible;
    user-select: none;
  `}
`;

export const Thumb = styled.img`
  ${({ theme: { borderRadius, colors } }) => css`
    border-radius: ${borderRadius.sm};
    height: 100%;
    object-fit: cover;
    position: relative;
    width: 100%;
    z-index: -1;
  `}
`;

export const IconBox = styled.div<{ $color: SocialIcon['background'] }>`
  ${({ theme: { colors }, $color }) => css`
    align-items: center;
    background: ${colors[$color]};
    border-radius: 1rem;
    bottom: -0.125rem;
    box-shadow: 0 0 0 2px ${colors.brandLight};
    display: flex;
    height: 0.875rem;
    justify-content: center;
    position: absolute;
    right: -0.125rem;
    width: 0.875rem;

    .icon {
      height: 0.625rem;
      width: 0.625rem;
    }
  `}
`;
