export const chartDummyData = {
  audience: {
    totals: {
      profileViews: {
        current: 1000,
        previous: 900,
        difference: 100,
        percentage: 11.1,
      },
      followers: {
        current: 300,
        previous: 280,
        difference: 20,
        percentage: 7.1,
      },
      netFollowers: {
        current: 578,
        previous: 421,
        difference: 157,
        percentage: 37.3,
      },
    },
    timeSeriesData: [
      {
        id: 'Profile Views',
        data: [
          {
            x: '2024-09-16T00:00:00.000Z',
            y: 50,
            key: 'profileViews-2024-09-16-0',
          },
          {
            x: '2024-09-17T00:00:00.000Z',
            y: 55,
            key: 'profileViews-2024-09-17-1',
          },
          {
            x: '2024-09-18T00:00:00.000Z',
            y: 60,
            key: 'profileViews-2024-09-18-2',
          },
          {
            x: '2024-09-19T00:00:00.000Z',
            y: 65,
            key: 'profileViews-2024-09-19-3',
          },
          {
            x: '2024-09-20T00:00:00.000Z',
            y: 70,
            key: 'profileViews-2024-09-20-4',
          },
          {
            x: '2024-09-21T00:00:00.000Z',
            y: 68,
            key: 'profileViews-2024-09-21-5',
          },
          {
            x: '2024-09-22T00:00:00.000Z',
            y: 72,
            key: 'profileViews-2024-09-22-6',
          },
        ],
      },
      {
        id: 'Followers',
        data: [
          {
            x: '2024-09-16T00:00:00.000Z',
            y: 20,
            key: 'followers-2024-09-16-0',
          },
          {
            x: '2024-09-17T00:00:00.000Z',
            y: 22,
            key: 'followers-2024-09-17-1',
          },
          {
            x: '2024-09-18T00:00:00.000Z',
            y: 25,
            key: 'followers-2024-09-18-2',
          },
          {
            x: '2024-09-19T00:00:00.000Z',
            y: 30,
            key: 'followers-2024-09-19-3',
          },
          {
            x: '2024-09-20T00:00:00.000Z',
            y: 32,
            key: 'followers-2024-09-20-4',
          },
          {
            x: '2024-09-21T00:00:00.000Z',
            y: 28,
            key: 'followers-2024-09-21-5',
          },
          {
            x: '2024-09-22T00:00:00.000Z',
            y: 35,
            key: 'followers-2024-09-22-6',
          },
        ],
      },
      {
        id: 'Net Followers',
        data: [
          {
            x: '2024-09-16T00:00:00.000Z',
            y: 5,
            key: 'netFollowers-2024-09-16-0',
          },
          {
            x: '2024-09-17T00:00:00.000Z',
            y: 6,
            key: 'netFollowers-2024-09-17-1',
          },
          {
            x: '2024-09-18T00:00:00.000Z',
            y: 10,
            key: 'netFollowers-2024-09-18-2',
          },
          {
            x: '2024-09-19T00:00:00.000Z',
            y: 15,
            key: 'netFollowers-2024-09-19-3',
          },
          {
            x: '2024-09-20T00:00:00.000Z',
            y: 20,
            key: 'netFollowers-2024-09-20-4',
          },
          {
            x: '2024-09-21T00:00:00.000Z',
            y: 18,
            key: 'netFollowers-2024-09-21-5',
          },
          {
            x: '2024-09-22T00:00:00.000Z',
            y: 15,
            key: 'netFollowers-2024-09-22-6',
          },
        ],
      },
    ],
  },
};
