/* eslint-disable @typescript-eslint/no-explicit-any */
import { ResponsiveLine, Serie } from '@nivo/line';
import { useTranslation } from 'react-i18next';
import { Heading, LAPTOP_MAX_SIZE, useWindowSize } from '@sendible/design-system';
import { makeNumbersReadFriendly } from '@sendible/frontendv2/src/utils';
import { getChartLeftMargin } from '@sendible/frontendv2/src/pages/Reports/helpers';
import { ChartContainer, Content } from '../../index.styles';
import { Tooltip } from '../../../../Audience/components/Tooltip';
import { formatDay, formatMonthAndDay } from '../../../../Audience/components/Chart';
import { Summary } from '../../..';

interface VideosViewsByDayChartProps {
  data: Serie[];
  bestPerformingDay: TikTokVideoBestDayPerformanceType;
}

export const VideosViewsByDayChart = ({ bestPerformingDay, data }: VideosViewsByDayChartProps) => {
  const { t } = useTranslation(['reports', 'common_terms']);
  const { width } = useWindowSize();

  const isSmallScreen = width < LAPTOP_MAX_SIZE;

  const firstDay = data[0].data[0].x;
  const mappedDataNumbers = data[0].data.map(({ y }) => y) as number[];

  const biggestValue = Math.max(...mappedDataNumbers);
  const smallestValue = Math.min(...mappedDataNumbers);
  const marginLeft = getChartLeftMargin(biggestValue, smallestValue);

  const summaryData = [
    {
      label: t('date', { ns: 'common_terms' }),
      value: bestPerformingDay.date,
    },
    {
      label: t('total_views'),
      value: bestPerformingDay.current,
    },
    {
      label: t('previous_period'),
      value: bestPerformingDay.previous,
    },
    {
      label: t('growth'),
      value: `+${bestPerformingDay.difference} (${bestPerformingDay.percentage}%)`,
    },
  ];

  return (
    <Content>
      <Heading
        variation="component-title_14"
        semantic="h4"
      >
        {t('performance_chart1_title')}
      </Heading>
      <ChartContainer>
        <ResponsiveLine
          data={data}
          margin={{
            top: isSmallScreen ? 50 : 20,
            right: isSmallScreen ? 24 : 120,
            bottom: 24,
            left: marginLeft,
          }}
          xScale={{
            type: 'point',
          }}
          yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            clamp: true,
            stacked: false,
            reverse: false,
          }}
          yFormat=" >-.2f"
          axisBottom={{
            format: (value) => {
              const dt = new Date(value);

              if (value === firstDay) return formatMonthAndDay(dt).replace(/(^|-)0+/g, '$1');
              if (dt instanceof Date && formatDay(dt) === '01') return formatMonthAndDay(dt).replace(/(^|-)0+/g, '$1');

              return dt instanceof Date ? formatDay(dt).replace(/(^|-)0+/g, '$1') : '';
            },
          }}
          axisLeft={{
            format: (v) => makeNumbersReadFriendly(v),
          }}
          enableGridX={false}
          enableGridY
          colors={['#000000']}
          lineWidth={4}
          enablePoints={false}
          enableArea
          enableCrosshair={false}
          useMesh
          tooltip={Tooltip}
          legends={
            !isSmallScreen
              ? [
                  {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                  },
                ]
              : [
                  {
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -32,
                    itemsSpacing: 2,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                  },
                ]
          }
        />
      </ChartContainer>
      <Summary
        title={t('performance_best_performing_day')}
        items={summaryData}
      />
    </Content>
  );
};
