import sharedStateBridge, { composeTabs, composeTabsInfo, COMPOSE_TABS } from '@sendible/shared-state-bridge';

const clearComposeBoxState = () => {
  sharedStateBridge.updateSpecificKey('compose', 'isComposeBoxOpen', false);
  sharedStateBridge.updateSpecificKey('compose', 'attachedMedias', undefined);
  sharedStateBridge.updateSpecificKey('compose', 'activeComposeBoxTab', undefined);
};

const setActiveComposeBoxTab = (tabId: composeTabs | undefined) => sharedStateBridge.updateSpecificKey('compose', 'activeComposeBoxTab', tabId);

const setIsComposeBoxOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isComposeBoxOpen', state);

const setIsMediaAttacherOpen = (state: boolean) => sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', state);

const toggleMediaAttacher = () => {
  const currentMediaAttacherState = sharedStateBridge.getSharedState().compose.isMediaAttacherOpen || false;

  sharedStateBridge.updateSpecificKey('compose', 'isMediaAttacherOpen', !currentMediaAttacherState);
};

const attachMessageTabMediasToOtherTabs = () => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;
  const { attachedMedias, presentComposeBoxTabsInfo } = sharedState;

  if (!attachedMedias || !attachedMedias[COMPOSE_TABS.MESSAGE]) return;

  const mediaInfo = attachedMedias;

  Object.keys(presentComposeBoxTabsInfo).forEach((tabName) => {
    if (presentComposeBoxTabsInfo[tabName].isCustomised === false) {
      mediaInfo[tabName] = Array.from(new Set([...mediaInfo[COMPOSE_TABS.MESSAGE]]));
    }
  });
  sharedStateBridge.updateSpecificKey('compose', 'attachedMedias', mediaInfo);
};

const attachToActiveTab = (mediasToAttach: number[]) => {
  const sharedState = sharedStateBridge.getSharedState()?.compose;
  const { activeComposeBoxTab, attachedMedias } = sharedState;

  setIsMediaAttacherOpen(false);

  const mediaInfo = {
    ...attachedMedias,
    [activeComposeBoxTab]: Array.from(
      new Set([...(attachedMedias && attachedMedias[activeComposeBoxTab] ? attachedMedias[activeComposeBoxTab] : []), ...mediasToAttach])
    ),
  };

  sharedStateBridge.updateSpecificKey('compose', 'attachedMedias', mediaInfo);

  if (activeComposeBoxTab === COMPOSE_TABS.MESSAGE) attachMessageTabMediasToOtherTabs();
};

const hasNewMedia = () => {
  return !!sharedStateBridge.getSharedState()?.compose?.attachedMedias;
};

const setPresentComposeBoxTabsInfo = (tabInfo: composeTabsInfo) => {
  sharedStateBridge.updateSpecificKey('compose', 'presentComposeBoxTabsInfo', tabInfo);
};

export const composeMediaInteractions = {
  setActiveComposeBoxTab,
  setIsComposeBoxOpen,
  setIsMediaAttacherOpen,
  setPresentComposeBoxTabsInfo,
  toggleMediaAttacher,
  clearComposeBoxState,
  hasNewMedia,
  attachToActiveTab,
  attachMessageTabMediasToOtherTabs,
};
