import { Datum, ResponsiveLine, Serie } from '@nivo/line';
import { Tooltip } from './Tooltip';

export const formatDay = (date: Date) => {
  return date.getDate().toString().padStart(2, '0');
};

export const formatMonthAndDay = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('en-US', { month: 'short' });

  return `${day} ${month}`;
};

type ChartProps = {
  timeSeriesData: Serie[];
  isSmallScreen: boolean;
};

export const Chart = ({ timeSeriesData, isSmallScreen }: ChartProps) => {
  const maxTicks = 3;
  const dataPoints = timeSeriesData.length || 10;
  const tickInterval = Math.max(1, Math.floor(dataPoints / maxTicks));
  const tickValues = timeSeriesData[0].data.map((datum: Datum) => datum.x).filter((_: unknown, index: number) => index % tickInterval === 0);
  const profileViewsData = timeSeriesData.find((datum: Datum) => datum.id === 'Profile Views')?.data;
  const areaBaselineValue = profileViewsData ? Math.min(...profileViewsData.map((d: Datum) => d.y as number)) : 0;
  const firstTickValue = tickValues[0];

  return (
    <ResponsiveLine
      data={timeSeriesData}
      margin={{
        top: isSmallScreen ? 50 : 20,
        right: isSmallScreen ? 24 : 100,
        bottom: 24,
        left: 40,
      }}
      xScale={{
        type: 'point',
      }}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: isSmallScreen ? tickValues : 30,
        format: (value) => {
          // Compare the current tick value with the first tick value
          if (value instanceof Date && firstTickValue instanceof Date && value.getTime() === firstTickValue.getTime()) {
            return formatMonthAndDay(value).replace(/(^|-)0+/g, '$1');
          }
          if (value instanceof Date && formatDay(value) === '01') {
            return formatMonthAndDay(value).replace(/(^|-)0+/g, '$1');
          }

          return value instanceof Date ? formatDay(value).replace(/(^|-)0+/g, '$1') : '';
        },
        legend: '',
        legendOffset: 36,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        tickValues: isSmallScreen ? 4 : 8,
        legend: '',
        legendOffset: -40,
        legendPosition: 'middle',
        truncateTickAt: 0,
      }}
      enableGridX={false}
      enableGridY
      colors={['#E10543', '#0075DB', '#000000']}
      lineWidth={4}
      enablePoints={false}
      pointSize={10}
      pointColor={{ theme: 'background' }}
      pointBorderWidth={2}
      pointBorderColor={{ theme: 'labels.text.fill' }}
      pointLabelYOffset={-15}
      enableArea
      areaBlendMode="multiply"
      areaBaselineValue={areaBaselineValue}
      enableCrosshair={false}
      useMesh
      tooltip={Tooltip}
      legends={
        !isSmallScreen
          ? [
              {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]
          : [
              {
                anchor: 'top-left',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: -32,
                itemsSpacing: 2,
                itemDirection: 'left-to-right',
                itemWidth: 100,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
              },
            ]
      }
    />
  );
};
