import { useLocation } from 'react-router-dom';
import { ListConfigType, ReportsDataArrayType, handleLinkClick } from './ReportsSidebar';

export const ReportLinks = (liConfig: ListConfigType, reportsData: ReportsDataArrayType) => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      {liConfig.map(({ href, reportName, icon, title }, index) => {
        const reportData = reportsData
          ? reportsData.find((report) => {
              return (!reportName && !report.account_type) || report.account_type === reportName;
            })
          : null;

        const hrefWithId = reportData ? `${href}${reportData.id}` : href;

        const isCampaigns = pathname.includes('campaigns');
        const isTikTok = pathname.includes('tiktok');

        const isActive = (isCampaigns && reportName === 'Campaigns') || (isTikTok && reportName === 'TikTok');

        return (
          <li
            className={`pre-filled ${isActive ? 'active' : ''}`}
            key={`fev2-reports-sidebar-${index}`}
          >
            <a
              href=""
              data-report-name={reportName}
              onClick={(event) => {
                handleLinkClick(event, hrefWithId);
              }}
            >
              {reportName === 'YouTube' || reportName === 'Campaigns' ? (
                <svg>
                  <use xlinkHref={`#svg-icon-${icon}`} />
                </svg>
              ) : (
                <span className={`icon ${icon}`} />
              )}
              {title}
            </a>
          </li>
        );
      })}
    </>
  );
};
