import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { ComposeDropdownProps } from './index';

export const Container = styled(motion.div)<{ $width: ComposeDropdownProps['width'] }>`
  ${({
    theme: {
      breakPoints: { smallDevices },
      fontSizes,
      colors,
    },
    $width = '17.5rem',
  }) => css`
    background: ${colors.brandLight};
    border-radius: 0.5rem;
    box-shadow: 0 1rem 3rem 0 ${colors.brandDarkAlpha15};
    box-shadow: 0px 0px 0px 1px ${colors.brandDarkAlpha10}, 0px 0.75rem 0.5rem -0.25rem ${colors.brandDarkAlpha15},
      0px 0.25rem 0.25rem -0.125rem ${colors.brandDarkAlpha20};
    width: ${$width};
    padding: 0.5rem 0;
    @media all and (${smallDevices.max}) {
      border-radius: 1rem 1rem 0 0;
      position: fixed !important;
      bottom: 0 !important;
      left: 0 !important;
      top: unset !important;
      right: unset !important;
      width: 100%;
    }
  `}
`;

export const Content = styled.ul`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    display: flex;
    flex: 1;
    flex-direction: column;
    list-style: none;
    margin: 0;
    max-height: 20rem;
    overflow-y: auto;
    padding: 0;

    li {
      padding: 0 0.5rem;
    }

    @media all and (${smallDevices.max}) {
      max-height: 80vh;
    }
  `}
`;

export const Badge = styled.div`
  ${({ theme: { colors, fontSizes } }) => css`
    color: ${colors.semanticInfoShade10};
    font-size: ${fontSizes.s12};
    font-weight: 500;
    text-transform: uppercase;
    height: 1.25rem;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;
    border: 1px solid ${colors.semanticInfoShade10};
    padding: 0 0.352rem;
  `}
`;

export const BadgeContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
`;

export const Feedback = styled.div<{ $type?: 'danger' | 'warning' }>`
  ${({ theme: { colors, fontSizes }, $type = 'danger' }) => css`
    margin: 0 0.5rem 0.5rem 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    font-size: ${fontSizes.s12};
    ${$type === 'danger' &&
    css`
      background: ${colors.semanticDangerTint90};
      color: ${colors.semanticDangerShade20};
    `}

    ${$type === 'warning' &&
    css`
      background: ${colors.semanticWarningTint80};
      color: ${colors.semanticWarningShade50};
    `}
  `}
`;

export const Header = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    @media all and (${smallDevices.max}) {
      background: ${colors.brandLight};
      border-radius: 1rem 1rem 0 0;
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0.25rem 1rem 1rem;
    }
  `}
`;

export const ListItem = styled.li`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    display: flex;
    flex: 1;
  `}
`;

export const Action = styled.button`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
      fontSizes,
    },
  }) => css`
    align-items: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    display: flex;
    height: 2.5rem;
    flex: 1;
    font-size: 1rem;
    gap: 1rem;
    margin: 0;
    padding: 0 1rem;
    user-select: none;
    font-size: ${fontSizes.s14};
    border-radius: 0.25rem;

    &:hover {
      color: ${colors.brandPrimary};
      background: ${colors.brandPrimaryAlpha10};
      svg path {
        stroke: ${colors.brandPrimary};
      }
    }

    &:focus-visible {
      outline: none;
    }

    svg {
      height: 1.25rem;
      width: 1.25rem;
      path {
        stroke: ${colors.brandDark};
      }
    }

    * {
      pointer-events: none;
    }

    :disabled {
      cursor: not-allowed;

      svg {
        filter: grayscale(1);
        opacity: 0.3;
      }
    }

    @media all and (${smallDevices.max}) {
      padding: 2rem;
      font-size: ${fontSizes.s16};
      height: 3.5rem;

      &:focus-visible {
        background: ${colors.brandPrimaryAlpha5};
        color: ${colors.brandPrimary};
        outline-color: transparent;
      }
    }
  `}
`;

export const Children = styled.div``;

export const Backdrop = styled(motion.div)`
  ${({
    theme: {
      breakPoints: { smallDevices },
      colors,
    },
  }) => css`
    @media all and (${smallDevices.max}) {
      background: ${colors.brandDarkAlpha90};
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
    }
  `}
`;

export const Spacer = styled.span`
  ${({ theme: { colors } }) => css`
    display: block;
    background: ${colors.brandDarkAlpha10};
    min-height: 1px;
    margin: 0.5rem 0;
    width: 100%;
  `}
`;
