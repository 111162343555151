import { AnimatePresence } from 'framer-motion';
import { createPortal } from 'react-dom';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useEffect, useRef, useState } from 'react';
import { Skeleton } from '@sendible/design-system/src';
import { Container, MediaSlot, MotionButton, Slider } from './index.styles';
import { ComposeMediaCard } from './components/ComposeMediaCard';
import { useGetMultipleMedias } from '../../models/medias';
import { useBackgroundUploaderContext } from '../BackgroundUploader/context';
import { shouldShowMedia } from '../BackgroundUploader/utils';

export const MediaAttached = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [sharedState] = useBridgeContext();
  const { composeBoxSkeletons, composeBoxUploadedFileIds } = useBackgroundUploaderContext();
  const activeComposeBoxTab = sharedState?.compose?.activeComposeBoxTab;
  const attachedMedias = sharedState?.compose?.attachedMedias;
  const { data } = useGetMultipleMedias(composeBoxUploadedFileIds);
  const medias = data?.result || undefined;

  const activeTabMedias =
    attachedMedias && medias
      ? medias.filter((media) => attachedMedias[activeComposeBoxTab].includes(media.id)).filter(({ status }) => shouldShowMedia(status))
      : [];

  const showContent = !!composeBoxUploadedFileIds.length || !!composeBoxSkeletons.length;

  /**
   * SLIDER AND PAGINATION CONTROLS
   */
  const gapBetweenMediaCards = 16;
  const mediaCardWidth = 104;
  const offset = (ref.current?.offsetParent as HTMLElement)?.offsetWidth || 680;
  const totalMediaSpace = mediaCardWidth + gapBetweenMediaCards;
  const mediasPerPage = offset / totalMediaSpace;
  const pages = activeTabMedias.length - mediasPerPage;

  const handlePrevSliderButton = () => {
    setPage((prev) => {
      if (prev === 0) return prev;

      return prev - 1;
    });
  };

  const handleNextSliderButton = () => {
    setPage((prev) => {
      if (prev >= pages) return prev;

      return prev + 1;
    });
  };

  useEffect(() => {
    if (activeTabMedias.length + composeBoxSkeletons.length > mediasPerPage && page > 0) setPage(0);
  }, [composeBoxSkeletons.length]);

  return createPortal(
    <Container>
      {!!showContent && (
        <>
          <AnimatePresence>
            {page > 0 ? (
              <MotionButton
                key="prev-button"
                className="attach-prev nav-button"
                icon="arrow_left"
                onClick={handlePrevSliderButton}
                size={16}
                initial={{ x: '-5rem' }}
                animate={{ x: 0 }}
                exit={{ x: '-5rem' }}
              />
            ) : null}
            {page < pages ? (
              <MotionButton
                key="next-button"
                className="attach-next nav-button"
                icon="arrow_right"
                onClick={handleNextSliderButton}
                size={16}
                initial={{ x: '5rem' }}
                animate={{ x: 0 }}
                exit={{ x: '5rem' }}
              />
            ) : null}
          </AnimatePresence>
          <Slider
            animate={ref.current ? { x: 0 * page - page * totalMediaSpace } : {}}
            transition={{ ease: 'easeInOut' }}
            ref={ref}
          >
            {composeBoxSkeletons.map((_, index) => (
              <MediaSlot key={`loading_${index}`}>
                <Skeleton
                  key={`loading_${index}`}
                  shape="square"
                  width="6.5rem"
                />
              </MediaSlot>
            ))}
            {activeTabMedias.map((media, index) => (
              <MediaSlot key={`${media.id}-${index}`}>
                <ComposeMediaCard
                  id={media.id}
                  ariaMediaCardLabel="compose-media-label"
                  orderNumber={activeTabMedias.length > 1 ? `${Number(index) + 1}` : ''}
                  tabIndex={0}
                  testId="asset-video"
                  thumbUrl={media.thumbnailUrl}
                  mediaType={media.type}
                  isGif={media.metadata && media.metadata?.mediaType?.includes('gif')}
                  dropdownButtonClick={(option: string) => {
                    // eslint-disable-next-line no-console
                    console.log(option);
                  }}
                  videoDuration={media.metadata?.duration}
                  open={(id: string | number) => {
                    // eslint-disable-next-line no-console
                    console.log(id);
                  }}
                />
              </MediaSlot>
            ))}
          </Slider>
        </>
      )}
    </Container>,
    document.getElementById('mediaAttached') as HTMLElement
  );
};
