import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { mediumDevices },
    },
  }) => css`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding: 0 2rem;

    @media all and (${mediumDevices.max}) {
      flex-direction: column;
      gap: 3rem;
    }
  `}
`;

export const Content = styled.div`
  ${({
    theme: {
      breakPoints: { mediumDevices },
    },
  }) => css`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: calc(50% - 0.5rem);

    @media all and (${mediumDevices.max}) {
      width: 100%;
    }
  `}
`;

export const ChartContainer = styled.div`
  height: 40vh;
  height: 40svh;
`;
