import { Icon, Text } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';

import {
  LeftSideContainer,
  LogoContainer,
  OverviewContainer,
  StatsBox,
  Avatar,
  StatsContainer,
  StatsHeader,
  StatsMetrics,
  StatsSubHeader,
  StatsWrapper,
  SubTitleText,
  TitleText,
  TrendArrow,
  WarningBox,
} from './index.styles';

export interface Metric {
  current: { value: number };
  label: string;
  previous: { value: number; difference: number; percentage: number; isPositive: boolean };
}

type OverviewTypes = {
  title: string;
  logo: string;
  metrics: Metric[];
  profileImage: string;
};

const Overview = ({ title, logo, metrics, profileImage }: OverviewTypes) => {
  const { t } = useTranslation('reports');

  const activityOverviewText = t('activity_overview');
  const delayText = t('report_delays');

  return (
    <OverviewContainer
      orientation="row"
      contentJustify="space-between"
      contentAlignment="flex-end"
      horizontalGap="s24"
    >
      <LeftSideContainer
        orientation="column"
        contentAlignment="flex-start"
      >
        <LogoContainer>
          <img
            src={logo}
            alt={title}
          />
        </LogoContainer>
        <TitleText color="brandDark">{title}</TitleText>
        <SubTitleText
          variation="body_16"
          color="brandDarkTint20"
        >
          {activityOverviewText}
        </SubTitleText>
        <WarningBox>
          <Icon name="info" />
          <Text variation="body_12">{delayText}</Text>
        </WarningBox>
      </LeftSideContainer>

      <StatsContainer
        orientation="row"
        contentJustify="flex-end"
        contentAlignment="flex-end"
      >
        <StatsWrapper horizontalGap="s24">
          {metrics.map(({ current: { value: currentValue }, label, previous: { difference, percentage, isPositive } }, index) => (
            <StatsBox
              key={index}
              orientation="column"
              horizontalGap="s16"
            >
              <StatsHeader>
                {currentValue.toLocaleString('en', {
                  notation: 'compact',
                  maximumFractionDigits: 1,
                })}
              </StatsHeader>
              <StatsSubHeader margin={['s0', 's0', 's8', 's0']}>
                {label}{' '}
                <Icon
                  name="help"
                  color="brandDark"
                />
              </StatsSubHeader>
              <StatsMetrics>
                <TrendArrow $isPositive={isPositive}>
                  <Icon
                    name={isPositive ? 'arrow_up_right' : 'arrow_down_left'}
                    color="brandLight"
                  />
                </TrendArrow>
                <Text
                  color={isPositive ? 'semanticSuccessShade50' : 'semanticDangerShade20'}
                  variation="body_14"
                >
                  {isPositive ? '+' : ''}
                  {difference} ({isPositive ? '+' : ''}
                  {percentage}%)
                </Text>
              </StatsMetrics>
            </StatsBox>
          ))}
        </StatsWrapper>
        <Avatar>
          <img
            src={profileImage}
            alt="Profile avatar"
          />
        </Avatar>
      </StatsContainer>
    </OverviewContainer>
  );
};

export { Overview };
