import { helpers } from '@sendible/design-system/src';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';

export type ComposeBoxContextType = {
  sessionId: string;
  setSessionId: Dispatch<SetStateAction<string>>;
};

export const ComposeBoxContext = createContext({} as ComposeBoxContextType);

export const ComposeBoxProvider = ({ children }: Component) => {
  const [sessionId, setSessionId] = useState<string>(helpers.generateId());
  const [sharedState] = useBridgeContext();

  useEffect(() => {
    if (sharedState.compose.isComposeBoxOpen) setSessionId(helpers.generateId());
  }, [sharedState?.compose?.isComposeBoxOpen]);

  return (
    <ComposeBoxContext.Provider
      value={{
        sessionId,
        setSessionId,
      }}
    >
      {children}
    </ComposeBoxContext.Provider>
  );
};

export const useComposeBoxContext = (): ComposeBoxContextType => useContext(ComposeBoxContext);
