import { fetchWithHeaders, getErrorFromFetchWithHeaders, throwError } from '@sendible/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBridgeContext } from '@sendible/shared-state-bridge';
import { useQuery } from '@tanstack/react-query';
import { tikTokPerformanceMockData, tikTokVideoMockData } from './mockData';
import { ReportsUrls } from '../../constants/urls';
import { useUrlWithCredentials } from '../../data-layer/useUrlWithCredentials';
import { useQueryWithAccessToken } from '../../data-layer/useReactQueryWithAccessToken';
import { audienceAPI } from '../../pages/Reports/tiktok/mocks';

export type PostModel = {
  comments: number;
  likes: number;
  message: string;
  postPicture: string;
  profileName: string;
  profilePicture: string;
  socialNetwork: string;
  uid: string;
  dateMessage: string;
};

interface UseGetTikTokProfilesReturnType {
  accounts: [
    {
      id: number;
      sender_type: 'tiktokbusiness';
      service_id: number;
      username: string;
      description: string;
      connection_url: string;
      service_image: string;
      avatar: string;
      service_description: 'TikTok';
      isShareable: number;
      isTaggable: true;
      reconnection_info: {
        needs_reconnecting: false;
        reconnection_url: string;
        reconnection_data: object;
        notify_reconnection: false;
      };
      contributors: string[];
    }
  ];
  pageCount: number;
}

const tikTokAudienceQueryKey: DLQueryKey = [ReportsUrls.tiktokAudience];
const tikTokProfilesQueryKey = ReportsUrls.tiktokProfiles;

export const useReportsModel = () => {
  const getUrlWithCredentials = useUrlWithCredentials();
  const quickReports = async () => {
    try {
      const url = getUrlWithCredentials(ReportsUrls.quickReports);

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      });
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getCampaignAudienceGrowth = async (campaignId: number) => {
    try {
      const url = `${getUrlWithCredentials(ReportsUrls.campaignAudienceGrowth)}&campaignId=${campaignId}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      }).then(({ result }) => result);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getReportCampaignOverview = async (campaignId: number) => {
    try {
      const url = `${getUrlWithCredentials(ReportsUrls.campaignOverview)}&campaignId=${campaignId}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      }).then(({ result }) => result);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const getCampaignPosts = async (campaignId: number, page: number, orderBy: string, socialNetwork: string) => {
    try {
      let parameters = `campaignId=${campaignId}&page=${page}`;

      if (orderBy !== '') {
        parameters = `${parameters}&orderBy=${orderBy}`;
      }
      if (socialNetwork !== '') {
        parameters = `${parameters}&socialNetwork=${socialNetwork}`;
      }

      const url = `${getUrlWithCredentials(ReportsUrls.campaignPosts)}&${parameters}`;

      return fetchWithHeaders({
        method: 'GET',
        url,
        headers: { 'Content-Type': 'application/json' },
      }).then(({ result }) => result);
    } catch (error: unknown) {
      throw Error(getErrorFromFetchWithHeaders(error));
    }
  };

  const useGetTikTokProfiles = () => {
    const [
      {
        user: { accessToken },
      },
    ] = useBridgeContext();

    const params = {
      access_token: accessToken,
      service_id: 20848,
    };

    return useQuery<UseGetTikTokProfilesReturnType>({
      queryKey: [tikTokProfilesQueryKey],
      queryFn: () => fetchWithHeaders({ method: 'GET', url: tikTokProfilesQueryKey, params }).then((res) => res),
      refetchInterval: 1200000,
    });
  };

  const useGetTikTokVideos = () => {
    return useQuery<UseGetTikTokVideosReturnType>({
      queryKey: ['tiktokvideos'],
      queryFn: () => fetchWithHeaders({ method: 'GET', url: '' }).then((res) => res),
      refetchInterval: 1200000,
      initialData: tikTokVideoMockData,
    });
  };

  const useGetTikTokPerformances = () => {
    return useQuery<UseGetTikTokPerformanceReturnType>({
      queryKey: ['tiktokperformance'],
      queryFn: () => fetchWithHeaders({ method: 'GET', url: '' }).then((res) => res),
      refetchInterval: 1200000,
      initialData: tikTokPerformanceMockData,
    });
  };

  const useGetTikTokAudience = () => {
    const { t } = useTranslation('reports');

    const profileViewsText = t('profile_views');
    const followersText = t('followers');
    const netFollowersText = t('net_followers');

    return useQueryWithAccessToken(tikTokAudienceQueryKey, {
      select: useCallback((APIResponse) => {
        if (!APIResponse) throwError('API response was empty when fetching TikTok profiles');

        const { totals, timeSeriesData } = APIResponse[0].data.audience;
        const userFriendlyMetricNames = {
          profileViews: profileViewsText,
          followers: followersText,
          netFollowers: netFollowersText,
        };

        const metrics = Object.keys(totals).filter((key) => key !== 'date');

        const modifiedTimeSeriesData = metrics.map((metric) => ({
          id: userFriendlyMetricNames[metric as keyof typeof userFriendlyMetricNames],
          data: timeSeriesData.map((item: { date: string; profileViews: number; followers: number; netFollowers: number }, index: number) => ({
            x: new Date(item.date),
            y: item[metric as keyof typeof item],
            key: `${metric}-${item.date}-${index}`,
          })),
        }));

        return { ...APIResponse[0].data, audience: { totals, timeSeriesData: modifiedTimeSeriesData } };
      }, []),
      refetchInterval: 1200000,
      initialData: audienceAPI,
      enabled: false,
    });
  };

  return {
    quickReports,
    getCampaignAudienceGrowth,
    getReportCampaignOverview,
    getCampaignPosts,
    useGetTikTokProfiles,
    useGetTikTokVideos,
    useGetTikTokPerformances,
    useGetTikTokAudience,
  };
};
