export const tikTokPerformanceMockData: UseGetTikTokPerformanceReturnType = {
  result: {
    videoViewsPerformance: {
      dailyViews: [
        {
          date: '2024-09-16',
          views: 4300,
        },
        {
          date: '2024-09-17',
          views: 4512,
        },
        {
          date: '2024-09-18',
          views: 5024,
        },
        {
          date: '2024-09-19',
          views: 6111,
        },
        {
          date: '2024-09-20',
          views: 6507,
        },
        {
          date: '2024-09-21',
          views: 7044,
        },
        {
          date: '2024-09-22',
          views: 7587,
        },
      ],
      bestPerformingDay: {
        date: '2024-09-23',
        current: 134,
        previous: 126,
        difference: 8,
        percentage: 9,
      },
    },
    interactionsByDay: {
      weeklyInteractions: [
        {
          day: 'Monday',
          likes: 1233,
          comments: 320,
          shares: 157,
        },
        {
          day: 'Tuesday',
          likes: 918,
          comments: 444,
          shares: 234,
        },
        {
          day: 'Wednesday',
          likes: 1497,
          comments: 400,
          shares: 253,
        },
        {
          day: 'Thursday',
          likes: 1040,
          comments: 302,
          shares: 189,
        },
        {
          day: 'Friday',
          likes: 1366,
          comments: 566,
          shares: 220,
        },
        {
          day: 'Saturday',
          likes: 1601,
          comments: 501,
          shares: 310,
        },
        {
          day: 'Sunday',
          likes: 1770,
          comments: 677,
          shares: 287,
        },
      ],
      bestEngagingDay: {
        date: '2024-09-23',
        current: 800,
        previous: 697,
        difference: 103,
        percentage: 9,
      },
    },
  },
};

export const tikTokVideoMockData: UseGetTikTokVideosReturnType = {
  result: {
    username: 'Sendible',
    profile: 343,
    videos: [
      {
        videoId: '7335477203552981003',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '7335477203552981004',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '7335477203552981005',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '7335477203552981006',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '7335477203552981007',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '7335477203552981008',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '7335477203552981009',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '73354772035529810010',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '73354772035529810011',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
      {
        videoId: '73354772035529810012',
        caption: `🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖`,
        thumbnailUrl: 'https://example.com/thumbnail.jpg',
        postedDate: '2024-09-23T10:34:00',
        shareUrl: 'https://example.com/45934758923479853443',
        views: 204,
        engagement: {
          likes: 134,
          comments: 126,
          shares: 28,
        },
        impressions: {
          fromForYou: 25,
          fromHashtags: 15,
          fromProfile: 10,
          fromSearch: 12,
          fromSound: 4,
          fromOther: 5,
          fromFollowers: 7,
        },
        watchMetrics: {
          avgTimeWatched: 12,
          totalTimeWatched: 345.32,
          fullVideoWatchedRate: 1535.54,
        },
      },
    ],
  },
  paging: {
    selfUrl: '/v0.1/tiktok/accounts/12/videos?startDate=2024-09-01&endDate=2024-09-30&page=2&pageSize=2',
    prevUrl: '/v0.1/tiktok/accounts/12/videos?startDate=2024-09-01&endDate=2024-09-30&page=1&pageSize=2',
    nextUrl: '/v0.1/tiktok/accounts/12/videos?startDate=2024-09-01&endDate=2024-09-30&page=3&pageSize=2',
    firstUrl: '/v0.1/tiktok/accounts/12/videos?startDate=2024-09-01&endDate=2024-09-30&page=1&pageSize=2',
    lastUrl: '/v0.1/tiktok/accounts/12/videos?startDate=2024-09-01&endDate=2024-09-30&page=5&pageSize=2',
  },
};
