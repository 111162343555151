import { useReportsModel } from '@sendible/frontendv2/src/models/reports';
import { InteractionsByDayChart } from './components/InteractionsByDayChart';
import { VideosViewsByDayChart } from './components/VideosViewsByDayChart';
import { Container } from './index.styles';

export const Performance = () => {
  const { useGetTikTokPerformances } = useReportsModel();
  const {
    data: {
      result: {
        interactionsByDay: { weeklyInteractions, bestEngagingDay },
        videoViewsPerformance: { dailyViews, bestPerformingDay },
      },
    },
  } = useGetTikTokPerformances();

  const videosViewsData = dailyViews.map(({ date, views }) => {
    return {
      x: date,
      y: views,
      key: `video-views-${date}`,
    };
  });

  return (
    <Container>
      {dailyViews && (
        <VideosViewsByDayChart
          bestPerformingDay={bestPerformingDay}
          data={[
            {
              id: 'Video views',
              data: videosViewsData,
            },
          ]}
        />
      )}
      <InteractionsByDayChart
        bestEngagingDay={bestEngagingDay}
        data={weeklyInteractions}
        indexBy="day"
      />
    </Container>
  );
};
