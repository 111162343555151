import { useTranslation } from 'react-i18next';
import { overviewAPI } from './mocks';

export const useGetOverviewData = () => {
  const { t } = useTranslation('reports');

  const profileViewsText = t('profile_views');
  const followersText = t('followers');
  const videoViewsText = t('video_views');
  const interactionsText = t('interactions');

  // Order of keys dictates the order UI components displayed
  const humanReadableLabels = {
    profileViews: profileViewsText,
    followers: followersText,
    videoViews: videoViewsText,
    interactions: interactionsText,
  };

  const { profileImage, ...metricsData } = overviewAPI;

  const metrics = Object.keys(humanReadableLabels).map((key) => {
    const metricsKeyData = metricsData[key as keyof typeof metricsData];

    return {
      key,
      label: humanReadableLabels[key as keyof typeof humanReadableLabels],
      current: metricsKeyData.current,
      previous: {
        ...metricsKeyData.previous,
        isPositive: metricsKeyData.previous.percentage >= 0,
      },
    };
  });

  return { metrics, profileImage };
};
