import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const $smallScreenWidth = 1150;

export const Container = styled.div<{ $isHidden: boolean }>`
  background: white;
  padding: 2rem;
  box-sizing: border-box;
  width: 100%;
  transition: filter 0.2s, opacity 0.2s;
  gap: 0.5rem;

  ${({ $isHidden }) =>
    $isHidden &&
    css`
      filter: blur(4px);
      opacity: 0.15;
      pointer-events: none;
    `}

  @media screen and (max-width: ${$smallScreenWidth}px) {
    flex-direction: column;
  }
`;

export const Header = styled.h2`
  font-size: 1.75rem;
`;

export const ChartContainer = styled(Box)<{ $width: string; $height: string }>`
  width: ${({ $width }) => $width};
  height: ${({ $height }) => $height};
`;

export const StatsContainer = styled(Box)`
  flex-grow: 1;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2rem;
  }

  h3 {
    font-size: 1.143rem;
  }
`;

export const TopMetrics = styled(Box)`
  width: 100%;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    align-self: flex-end;
    width: unset;
  }
`;

export const StatsTable = styled(Box)`
  width: 100%;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    width: unset;
  }
`;

export const StatRow = styled(Box)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.brandDarkTint80};
    width: 100%;
  `}
`;

export const LargeStatContainer = styled(Box)`
  width: 100%;

  @media screen and (max-width: ${$smallScreenWidth}px) {
    width: unset;
  }
`;

export const LargeStat = styled.div`
  font-size: 3rem;
  font-weight: 900;
  margin-bottom: 1rem;
`;

export const WarningContainer = styled(Box)`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const WarningTextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin: 0 5rem;

  svg {
    flex-shrink: 0;
  }
`;

export const TrendContainer = styled(Box)`
  flex-wrap: nowrap;
`;
