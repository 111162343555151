import { createPortal } from 'react-dom';
import { ComposeBox, Wrapper } from './components';
import { AiAssistProvider } from './AiAssistContext';
import { Preview } from './components/Preview';
import { QuickActions } from './types';

type AiAssistProps = {
  quickAction: QuickActions | null;
  composeText: string;
};

const AiAssist = ({ quickAction, composeText }: AiAssistProps) => {
  return (
    <>
      {createPortal(
        <AiAssistProvider
          quickAction={quickAction}
          composeText={composeText}
        >
          <Wrapper>
            <Preview />
            {!quickAction && <ComposeBox />}
          </Wrapper>
        </AiAssistProvider>,
        document.getElementById('modal-root') as HTMLElement
      )}
    </>
  );
};

export default AiAssist;
