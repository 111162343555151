export const CONTENT_LIBRARIES = 'Content.Libraries';
export const MEASURE_REPORTBUILDER = 'Measure.ReportBuilder';
export const MEASURE_EXPORTCSV = 'Measure.ExportCSV';
export const COMPOSE_CUSTOMWORKFLOWS = 'Compose.CustomWorkflows';
export const PREFERENCES_BITLYPRO = 'Preferences.BitlyPro';
export const ADMIN_PERMISSIONGROUPS = 'Admin.PermissionGroups';
export const ADMIN_CUSTOMFIELDS = 'Admin.CustomFields';
export const COMPOSE_DROPBOXGOOGLEDRIVE = 'Compose.DropboxGoogleDrive';
export const COMPOSE_UTM = 'Compose.UTM';
export const COMPOSE_SENDFORAPPROVAL = 'Compose.SendForApproval';
export const COMPOSE_QUEUES = 'Compose.Queues';
export const COMPOSE_AIASSIST = 'Compose.AiAssist';
export const COMPOSE_CAMPAIGNS = 'Compose.Campaigns';
export const TIKTOK_REPORTS = 'tiktokQuickReport';

export const pendoGuideLookup = {
  admin: {
    [CONTENT_LIBRARIES]: 'NG_gElbPOx0HKcDzctQrnobaGnY',
    [MEASURE_REPORTBUILDER]: 'UhIPzPSF_w2pwo1UDqn_3ohSAY4',
    [MEASURE_EXPORTCSV]: 'obYqJ0wI7gvKPBdXqCjwkdDvPHM',
    [COMPOSE_CUSTOMWORKFLOWS]: 'sSWpZGV9da3HAdIeG7SKdk-IA98',
    [PREFERENCES_BITLYPRO]: 'GZKgtAIALY_5Jiwmwf784mrXaWs',
    [ADMIN_PERMISSIONGROUPS]: 'egCcxpXNndRJki9FCcIjWUtp8LU',
    [ADMIN_CUSTOMFIELDS]: 'HkpSRO9zuFgNYUdxHSfJuoUPm6Q',
    [COMPOSE_DROPBOXGOOGLEDRIVE]: '6MsTT4K0vPig1Cze2aR3PJwS_qk',
    [COMPOSE_UTM]: 'AgORliLmRlKEpFAWRaKdeY3dUq4',
    [COMPOSE_SENDFORAPPROVAL]: 'PDTzBQFo4zYntGlobaFvFjOkwPM',
    [COMPOSE_QUEUES]: 'GGSlk6YdrfHMN0TVyGMQYHgG0Pg',
    [COMPOSE_AIASSIST]: '62rHnlA1W9J4DbdjM-SFziZQaA8',
    [COMPOSE_CAMPAIGNS]: 'IZzujd6eqzNrNXWs8cx5ZWF4iPY',
  },
  team_member: {
    [CONTENT_LIBRARIES]: 'KAj6jNol5cXkJYKpLuUhKgaGq6I',
    [MEASURE_REPORTBUILDER]: 'UwedUeXZXTuLieLPqe10n8hqM6c',
    [MEASURE_EXPORTCSV]: 'IHx0dDgNcR-qg0ce69XrZr3XCQE',
    [PREFERENCES_BITLYPRO]: 'xIV26JZmJTJZwVjwxad_nDAfUsg',
    [ADMIN_CUSTOMFIELDS]: '1Ljgyu2imYfTVjwRIHphLbVC44M',
    [COMPOSE_DROPBOXGOOGLEDRIVE]: 'RLCiZi54mVCPJ6nCbDB4AT3GVNU',
    [COMPOSE_UTM]: 'FfPVigSsfFF2kyEtm4udcVte4qk',
    [COMPOSE_SENDFORAPPROVAL]: ' LPjPPd_yd3RZ_drlEcVhGoHHsxw',
    [COMPOSE_QUEUES]: 'J38-XKDFvH4nrbR8hhq27mMTfv8',
    [COMPOSE_AIASSIST]: 'WzoLszss50qjGa9bUp42WSeeSro',
    [COMPOSE_CAMPAIGNS]: 'ai6VxSJm6JYlfr0KWtxRc4Ms6es',
  },
};

export const status = {
  AVAILABLE: 'available',
  LIMITED: 'limited',
  HIDDEN: 'hidden',
  RESTRICTED: 'restricted',
};
