import { motion } from 'framer-motion';
import { Button, Text } from '@sendible/design-system';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const SliderContainer = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    display: flex;
    position: relative;
    justify-content: space-between;
    scroll-snap-type: x mandatory;
    overflow: hidden;

    .nav-button {
      position: absolute;
      background: ${colors.brandLight};
      box-shadow: 0px 1px 18px 0px ${colors.brandDarkAlpha10}, 0px 6px 10px 0px ${colors.brandDarkAlpha15},
        0px 3px 5px -1px ${colors.brandDarkAlpha20};
      z-index: 100;

      &:hover {
        background: ${colors.brandLight};
        transform: scale(1.1);
      }
    }

    .attach-prev {
      left: 2rem;
    }

    .attach-next {
      right: 2rem;
    }

    &::-webkit-scrollbar {
      display: none !important;
    }
  `}
`;

export const Slider = styled(motion.div)`
  align-items: flex-start;
  display: flex;
  gap: 2rem;
  padding: 0 2rem;
`;

export const SectionOptions = styled.div`
  align-items: center;
  display: flex;
  gap: 0.5rem;
  padding: 0 2rem;
`;

export const CustomText = styled(Text)`
  font-weight: 500;
  letter-spacing: 0.07px;
`;

export const PaginationSection = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const MotionButton = styled(motion(Button))``;
