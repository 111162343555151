import { MutationMethodEnum } from './types';

export default {
  // Queries
  GetMediaLibraries: {
    endpoint: '/0.1/tw/media_libraries',
    interval: 600000, // 10 minutes
  },
  GetMedias: {
    endpoint: '/0.1/tw/media',
    interval: 300000, // 5 minutes
  },
  GetMedia: {
    endpoint: '/0.1/tw/media',
    interval: 30000, // 30 seconds
  },
  // Mutations
  CreateMediaLibrary: {
    endpoint: '/0.1/tw/media_libraries',
    method: MutationMethodEnum.post,
  },
  RenameMediaLibrary: {
    endpoint: '/0.1/tw/media_libraries',
    method: MutationMethodEnum.put,
  },
  DeleteMediaLibrary: {
    endpoint: '/0.1/tw/media_libraries',
    method: MutationMethodEnum.delete,
  },
  UploadMedias: {
    endpoint: '/0.1/tw/uploads',
    method: MutationMethodEnum.post,
  },
  DeleteMedia: {
    endpoint: '/0.1/tw/media',
    method: MutationMethodEnum.delete,
  },
  RenameMedia: {
    endpoint: '/0.1/tw/media',
    method: MutationMethodEnum.put,
  },
  CompleteUploadMedias: {
    endpoint: '/0.1/tw/uploads',
    method: MutationMethodEnum.put,
  },
  AbortUploadMedias: {
    endpoint: '/0.1/tw/uploads',
    method: MutationMethodEnum.delete,
  },
} as const;
