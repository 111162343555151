import { Box, Text } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const OverviewContainer = styled(Box)`
  background: white;
  flex-wrap: unset;
  padding: 4.571rem 2.286rem 2.286rem 2.286rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const LeftSideContainer = styled(Box)`
  @media screen and (max-width: 768px) {
    box-sizing: border-box;
    width: 100%;
    padding: 4.571rem 2.286rem 2.286rem 2.286rem;
  }
`;

export const TitleText = styled(Text)`
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;

export const SubTitleText = styled(Text)`
  margin-bottom: 1rem;
`;

export const LogoContainer = styled.div`
  display: flex;
  margin-bottom: 1.143rem;
  width: 32px;
`;

export const WarningBox = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 0.5rem;

  svg {
    flex-shrink: 0;
  }

  > p {
    margin-top: 0.3rem;
  }
`;

export const StatsContainer = styled(Box)`
  flex-wrap: wrap-reverse;
  flex: 1;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const StatsWrapper = styled(Box)`
  flex-wrap: nowrap;
  margin-left: 1rem;
`;

export const StatsBox = styled(Box)`
  max-height: 160px;
`;

export const TrendArrow = styled.span<{ $isPositive: boolean }>`
  ${({ theme: { colors }, $isPositive }) => css`
    position: relative;
    margin-right: 0.3rem;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    ${$isPositive &&
    css`
      &::before {
        background-color: ${colors.semanticSuccessShade50};
      }
    `}

    ${!$isPositive &&
    css`
      &::before {
        background-color: ${colors.semanticDangerShade20};
      }
    `}

    svg {
      width: 20px;
      height: 20px;
      display: block;
      position: relative;
    }
  `}
`;

export const StatsSubHeader = styled(Box)`
  font-weight: 500;
  color: black !important;
  flex-wrap: nowrap;
  white-space: nowrap;

  svg {
    width: 18px;
    margin-left: 0.5rem;
  }
`;

export const StatsMetrics = styled(Box)`
  flex-wrap: nowrap;
  white-space: nowrap;
`;

export const StatsHeader = styled(Text)`
  font-weight: 500;
  font-size: 2.625rem;
  line-height: 2.625rem;
  margin-bottom: 0.571rem;
`;

export const Avatar = styled(Box)`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandPrimaryTint40};
    border: 2px solid ${colors.brandDarkAlpha20};
    border-radius: 1rem;
    font-size: 2.514rem;
    width: 88px;
    height: 88px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin: 0 0 1rem 1rem;
    overflow: hidden;

    p {
      color: ${colors.brandDark};
      font-size: 2.625rem;
      font-weight: 500;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    @media screen and (max-width: 768px) {
      order: -1;
      align-self: flex-end;
    }
  `}
`;
