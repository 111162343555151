import { useEffect, useState } from 'react';
import { validators } from '@sendible/common';
import Editor from './editor';

export const EDITOR_EXPORT = 'creative-editor-export';
export const EDITOR_CLOSE = 'creative-editor-export-close';

const PhotoEditor = () => {
  const [preloadEditor, setPreloadEditor] = useState<boolean>(false);

  const [data, setData] = useState<{ fileUrl: string; serviceSet: string; hasPinterest: boolean }>({
    fileUrl: '',
    serviceSet: '',
    hasPinterest: false,
  });

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (validators.validatePostMessageSource(event)) {
        const { eventName, data: eventData } = event.data;

        if (eventName === 'preload-editor' && !preloadEditor) {
          setPreloadEditor(true);
        }

        if (eventName === 'open-editor') {
          setData({ fileUrl: eventData.fileUrl, serviceSet: eventData.serviceSet, hasPinterest: eventData.hasPinterest });
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // do not load the Editor component until the preload flag is set
  // this ensure any performance hit from initialising CESDK
  // is delayed unit the feature is ready to use
  if (!preloadEditor) {
    return null;
  }

  return (
    <Editor
      fileUrl={data.fileUrl}
      serviceSet={data.serviceSet}
      hasPinterest={data.hasPinterest}
      resetState={() => {
        setData({ fileUrl: '', serviceSet: '', hasPinterest: false });
      }}
    />
  );
};

export default PhotoEditor;
