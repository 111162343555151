import { Icon } from '@sendible/design-system/src';
import {
  Caption,
  Container,
  DateInfo,
  IconBox,
  ProfileThumbBox,
  Thumb,
  Engagements,
  EngagementItem,
  Profile,
  ProfileInfo,
  Top,
} from './index.styles';

interface Engagement {
  likes: number;
  comments: number;
  shares: number;
}

interface TikTokVideoCardProps {
  caption: string;
  engagement: Engagement;
  postedDate: string;
  profileAvatar: string;
  profileName: string;
  shareUrl: string;
  thumbnailUrl: string;
  userName: string;
  videoId: string;
  views: number;
}

export const TikTokVideoCard = ({
  caption,
  engagement,
  postedDate,
  profileAvatar,
  profileName,
  shareUrl,
  thumbnailUrl,
  userName,
  videoId,
  views,
}: TikTokVideoCardProps) => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const date = new Date(postedDate);
  const localePostedDate = date.toLocaleTimeString(navigator.language, options);

  return (
    <Container>
      <Top>
        <DateInfo>{localePostedDate}</DateInfo>
        <Profile>
          <ProfileThumbBox>
            <IconBox $color="socialTiktok">
              <Icon
                color="brandLight"
                name="tiktok"
                useStroke={false}
              />
            </IconBox>
            <img
              alt=""
              src={profileAvatar}
            />
          </ProfileThumbBox>
          <ProfileInfo>
            <p>
              <b>{userName}</b>
            </p>
            <p>{userName}</p>
          </ProfileInfo>
        </Profile>
        <Caption>{caption}</Caption>
      </Top>
      <Thumb
        href={shareUrl}
        target="_blank"
        rel="noreferrer no"
      >
        <Icon name="play" />
        <img
          src={thumbnailUrl}
          alt={videoId}
        />
      </Thumb>
      <Engagements>
        <EngagementItem>
          <p>
            <b>Views</b>
          </p>
          <p>{views}</p>
        </EngagementItem>
        <EngagementItem>
          <p>
            <b>Likes</b>
          </p>
          <p>{engagement.likes}</p>
        </EngagementItem>
        <EngagementItem>
          <p>
            <b>Comments</b>
          </p>
          <p>{engagement.comments}</p>
        </EngagementItem>
        <EngagementItem $hideBorder>
          <p>
            <b>Shares</b>
          </p>
          <p>{engagement.shares}</p>
        </EngagementItem>
      </Engagements>
    </Container>
  );
};
