import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Heading, Icon, Spinner } from '@sendible/design-system/src';
import { AxiosError } from 'axios';
import { pages } from '@sendible/frontendv2/src/routes';
import { ErrorState } from '@sendible/frontendv2/src/components';
import { useReportsModel } from '@sendible/frontendv2/src/models/reports';
import {
  ReportContainer,
  ReportMessageContainer,
  NoReportsContainer,
  NoReportsErrorContainer,
  ReportLoadingOverlay,
  Sections,
  Section,
  SectionHeader,
} from './index.styles';
import { useGetOverviewData } from './hooks';
import TikTokLogo from '../assets/tiktok-logo.png';
import { Overview } from '../components';
import { useReportsContext } from '../context';
import { Header, Performance, Videos } from '../components/TikTok';
import { Audience } from '../components/Audience';

const TikTokReport = () => {
  const { id: paramIdString } = useParams();
  const [currentProfile, setCurrentProfile] = useState<TikTokAccountType | null>(null);
  const { isPrint } = useReportsContext();
  const { t } = useTranslation('reports');
  const navigate = useNavigate();
  const { metrics, profileImage } = useGetOverviewData();
  const tempDateRange: ReportDateRange = {
    startDate: new Date('2024-07-21'),
    endDate: new Date('2024-07-28'),
  };
  const { useGetTikTokProfiles } = useReportsModel();
  const { data: getTikTokProfilesData, error, isLoading } = useGetTikTokProfiles();

  const paramId = paramIdString ? parseInt(paramIdString, 10) : null;
  const profiles = getTikTokProfilesData?.accounts || [];

  const handleReportRouteByProfileObject = (profileObject: TikTokAccountType | undefined) => {
    if (profileObject) {
      setCurrentProfile(profileObject);
      navigate(`${pages.Measure.tiktok}/${profileObject.id}`);
    } else if (profiles.length) {
      setCurrentProfile(profiles[0]);
      navigate(`${pages.Measure.tiktok}/${profiles[0].id}`);
    } else {
      setCurrentProfile(null);
    }
  };

  useEffect(() => {
    if (profiles.length) {
      const current = profiles.find((profile) => paramId && profile.id === paramId);

      handleReportRouteByProfileObject(current);
    }
  }, [paramId, profiles]);

  if (error !== null) {
    const err = error as AxiosError;

    return (
      <ErrorState
        errorMessage={err.message}
        translationNamespace="reports"
      />
    );
  }

  if (isLoading) {
    return (
      <ReportMessageContainer>
        <Spinner size="lg" />
      </ReportMessageContainer>
    );
  }

  if (!currentProfile) {
    return (
      <NoReportsContainer>
        <Heading semantic="h1">{t('tiktok_report')}</Heading>
        <NoReportsErrorContainer>
          <Icon name="megaphone" />
          <Heading semantic="h4">{t('no_reports_available')}</Heading>
          <Button
            onClick={() => {
              navigate(`#services`);
            }}
            label={t('tiktok_add_profile') as string}
            size={14}
            appearance="primary"
            variation="fill"
          />
        </NoReportsErrorContainer>
      </NoReportsContainer>
    );
  }

  return (
    <>
      {isPrint && (
        <ReportLoadingOverlay>
          <Spinner size="lg" />
        </ReportLoadingOverlay>
      )}
      <ReportContainer className={`v2-report-container ${isPrint ? 'print' : ''}`}>
        <Header
          reportsList={profiles}
          currentReportId={currentProfile.id as number}
          profileType="tiktok"
          profileSelect={(reportObject) => {
            handleReportRouteByProfileObject(reportObject);
          }}
        />
        <Sections>
          <Section>
            <SectionHeader>
              <Heading
                semantic="h2"
                variation="layout-title_28"
              >
                {t('performance_title')}
              </Heading>
            </SectionHeader>
            <Performance />
          </Section>
          <Section>
            <Overview
              title={t('tiktok_name')}
              logo={TikTokLogo}
              metrics={metrics}
              profileImage={profileImage}
            />
          </Section>
          <Section>
            <SectionHeader>
              <Heading
                semantic="h2"
                variation="layout-title_28"
              >
                {t('audience_header')}
              </Heading>
            </SectionHeader>
            <Audience dateRange={tempDateRange} />
          </Section>
          {currentProfile && (
            <Section>
              <SectionHeader>
                <Heading
                  semantic="h2"
                  variation="layout-title_28"
                >
                  {t('videos_title')}
                </Heading>
              </SectionHeader>
              <Videos currentProfile={currentProfile} />
            </Section>
          )}
        </Sections>
      </ReportContainer>
    </>
  );
};

export default TikTokReport;
