import { useRef, useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BulkActionsBar, Button, Dropdown, Heading, Input } from '@sendible/design-system';
import { createPortal } from 'react-dom';
import { useUploadFiles } from '@sendible/frontendv2/src/models/uploads';
import { ContainerStyled } from './index.styles';
import { useMediaLibraryContext } from '../../context';
import { BoxStyled } from '../../index.styles';
import { useInfiniteGetMedias } from '../../../../models/medias';
import { MEDIA_LIBRARY_ACTION_DELETE, searchInputThrottle } from '../../constants';
import useMediaLibraryOptions from '../../utils/useMediaLibraryOptions';
import { DeleteMultipleMediasModal } from '..';
import { multipleMediasDeletedBulkBar, singleMediaDeletedBulkBar } from '../../pendoEvents';

export const Header = () => {
  const { activeMediaLibrary, setSidebarIsVisible, mediaSearch, setMediaSearch, isMobile, selectedMediasIds, setSelectedMediasIds } =
    useMediaLibraryContext();
  const [mediaSearchTimeoutId, setMediaSearchTimeoutId] = useState<NodeJS.Timeout>();
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { getMediaLibraryOptions } = useMediaLibraryOptions();
  const { data } = useInfiniteGetMedias();
  const [isBulkPostingDisabled, setIsBulkPostingDisabled] = useState(true);
  const { t } = useTranslation('media_library');
  const { uploadFiles } = useUploadFiles({ uploadingFrom: 'media-library' });
  const uploadButtonLabel = t('header_upload_button');
  const ariaUploadMediaLabel = t('aria_upload_media');
  const ariaSearchCurrentLabel = t('aria_search_current');
  const mediaLibraryDropdownOptions = getMediaLibraryOptions(activeMediaLibrary) || [];
  const BULK_POST_ACTION = 'bulkpost';

  const mediaList = data?.pages.map((medias) => medias).flat();

  useEffect(() => {
    let hasVideo = false;

    const selectedMediaData = data?.pages.flat().filter((media) => {
      const isSelected = selectedMediasIds.includes(media.id);

      if (!isSelected) {
        return false;
      }

      if (media.type === 'Video') {
        hasVideo = true;
      }

      return true;
    });

    const selectedMediasLength = selectedMediaData?.length || 0;

    if (selectedMediasLength === 0 || selectedMediasLength > 15 || (hasVideo && selectedMediasLength > 1)) {
      setIsBulkPostingDisabled(true);
    } else {
      setIsBulkPostingDisabled(false);
    }
  }, [selectedMediasIds]);

  const onFilesChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files) {
      return;
    }

    uploadFiles(Array.from(files));
  };

  const onUploadButtonClick = () => {
    hiddenFileInput?.current?.click();
  };

  const mediaSearchHandleThrottled = (value: string) => {
    const timeoutId = setTimeout(() => setMediaSearch(value), searchInputThrottle);

    if (mediaSearchTimeoutId) {
      clearInterval(mediaSearchTimeoutId);
    }
    setMediaSearchTimeoutId(timeoutId);
  };

  const handleDropdownAction = (action: string) => {
    if (mediaLibraryDropdownOptions.actionHandlers[action]) {
      mediaLibraryDropdownOptions.actionHandlers[action]();
    }
  };

  const handleBulkAction = (action: string) => {
    type OptionsType = {
      [key: string]: () => void;
    };

    const options: OptionsType = {
      [MEDIA_LIBRARY_ACTION_DELETE]: () => setIsBulkDeleteModalOpen(true),
    };

    if (options[action]) {
      options[action]();
    }
  };

  return (
    <ContainerStyled padding={['s8', 's24', 's8', 's24']}>
      {isBulkDeleteModalOpen &&
        createPortal(
          <DeleteMultipleMediasModal
            closeCallback={() => setIsBulkDeleteModalOpen(false)}
            pendoEvent={selectedMediasIds.length > 1 ? multipleMediasDeletedBulkBar : singleMediaDeletedBulkBar}
          />,
          document.getElementById('modal-root') as HTMLElement
        )}
      <BoxStyled
        contentAlignment="center"
        contentJustify="space-between"
        padding={['s0', 's0', 's16', 's0']}
        overflow="nowrap"
      >
        <Box
          className="name-container"
          flex={1}
          horizontalGap="s28"
        >
          <Heading
            align="left"
            color="brandDark"
            semantic="h4"
            variation="component-title_16"
            data-testid="media-library-name"
            maxLines={1}
          >
            {activeMediaLibrary?.name}
          </Heading>
          {isMobile && (
            <Button
              type="button"
              onClick={() => setSidebarIsVisible(true)}
              icon="select"
              size={12}
              data-testid="open-sidebar-mobile"
            />
          )}
        </Box>
        <Box
          horizontalGap="s12"
          className="button-container"
          contentAlignment="center"
        >
          <Button
            appearance="default"
            aria-label={ariaUploadMediaLabel}
            type="button"
            icon="upload_cloud"
            iconPosition="left"
            size={14}
            variation="outline"
            floatAppearance="primary"
            label={uploadButtonLabel}
            onClick={onUploadButtonClick}
            disabled={!data}
            data-testid="upload-header-button"
          />
          {getMediaLibraryOptions(activeMediaLibrary) && (
            <Dropdown
              options={mediaLibraryDropdownOptions.optionsList}
              select={handleDropdownAction}
            >
              <Button
                icon="more_horizontal"
                title="options"
                size={14}
                customClass="sidebar-nav-item-button"
              />
            </Dropdown>
          )}
        </Box>
        <input
          type="file"
          multiple
          accept="video/*, image/*"
          hidden
          onChange={onFilesChange}
          onClick={(event) => {
            // this is forcing onChange to be triggered even if you select the same file
            event.currentTarget.value = '';
          }}
          ref={hiddenFileInput}
          data-testid="upload-header-input"
        />
      </BoxStyled>
      {selectedMediasIds.length > 0 ? (
        <BulkActionsBar
          act={(action) => handleBulkAction(action)}
          actionButtons={[
            {
              action: BULK_POST_ACTION,
              icon: 'send',
              isDisabled: isBulkPostingDisabled,
              size: 16,
              label: isBulkPostingDisabled ? 'Post up to 15 images or 1 video at once' : ' Bulk Post',
            },
            { icon: 'trash', size: 16, label: 'Delete', action: 'delete' },
          ]}
          deselectAll={() => setSelectedMediasIds([])}
          maxItemsCount={mediaList?.length || 0}
          selectAll={() => {
            const allMediasIds = mediaList?.map((media) => media.id);

            setSelectedMediasIds(allMediasIds || []);
          }}
          selectedItemsCount={selectedMediasIds.length}
          selectedItemsLabel={t('bulkbar_selected')}
        />
      ) : (
        <Input
          label={t('header_search')}
          size="md"
          testId="search-input"
          value={mediaSearch}
          change={(currentMediaSearch) => {
            mediaSearchHandleThrottled(currentMediaSearch);
          }}
          type="text"
          icon="search"
          ariaLabel={ariaSearchCurrentLabel}
        />
      )}
    </ContainerStyled>
  );
};
