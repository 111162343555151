export default {
  activity_overview: 'Activity Overview',
  audience_change_title: 'Audience Change',
  audience_chart_audience_legend: 'Audience',
  audience_chart_decreased_by: 'Your audience decreased by',
  audience_chart_growth_by_day: 'Audience Growth By Day',
  audience_chart_increased_by: 'Your audience increased by',
  audience_chart_split: 'Audience Split',
  audience_chart_title: 'Audience Growth',
  audience_error:
    "We couldn't load this report. Please refresh your browser and try again. If the issue persists after 24 hours, contact our support team.",
  audience_header: 'Audience',
  campaign_overview_title: 'Campaigns Overview',
  campaigns_report: 'Campaigns report',
  compared_to_previous_period: 'Compared to previous period',
  default_error_message:
    "We couldn't create your report. Please refresh your browser and try again. If the issue persists after 24 hours, contact our support team.",
  default_error_message_whitelabel:
    "We couldn't create your report. Please refresh your browser and try again. If the issue persists after 24 hours, contact your admin.",
  download_data_analytics_sidebar_link: 'Download data',
  download_report_tooltip: 'Download PDF report',
  empty_report: 'No data available. Check back after your first post has been published.',
  engaging_posts: 'Most engaging posts',
  error_message: 'Reports could not be loaded',
  facebook_post_engagement_title: 'Facebook Post Engagement',
  facebook_sidebar_link: 'Facebook Page',
  followers: 'Followers',
  generate_report_button: 'Generate Report',
  google_analytics_sidebar_link: 'Google Analytics',
  growth: 'Growth',
  instagram_post_engagement_title: 'Instagram Post Engagement',
  instagram_sidebar_link: 'Instagram',
  interactions: 'Interactions',
  linkedin_post_engagemen_titlet: 'LinkedIn Post Engagement',
  linkedin_sidebar_link: 'LinkedIn',
  metrics: 'Metrics',
  my_reports_sidebar_link: 'My reports',
  needs_more_data: 'Data will appear once your account reaches 100 followers. Keep posting to grow your audience!',
  net_followers: 'Net Followers',
  net_followers_growth: 'Net followers growth',
  no_reports_available: 'No data available. Check back after your first post has been published.',
  not_defined: 'Not defined',
  not_more_data_error_message: 'No more data available.',
  performance_title: 'Performance',
  performance_best_engaging_day: 'Best engaging day',
  performance_best_performing_day: 'Best performing day',
  performance_chart1_title: 'Video views by day',
  performance_chart2_title: 'Interactions by day',
  previous_period: 'Previous period',
  profile_views: 'Profile Views',
  report_builder_sidebar_link: 'Report builder',
  report_campaign_sidebar_link: 'Campaigns',
  report_delays: 'There may be a delay of 24 to 48 hours for some displayed data.',
  report_engagement_sidebar_link: 'Engagement',
  report_sidebar_title: 'Reports Hub',
  report_tiktok_sidebar_link: 'TikTok',
  since_previous_period: 'Since previous period',
  tiktok_add_profile: 'Add TikTok Profile',
  tiktok_name: 'TikTok',
  tiktok_report: 'TikTok report',
  tooltip_audience: 'Total fans and followers generated by campaign posts (only Facebook, Instagram, and LinkedIn, if applicable).',
  tooltip_interactions: 'Total likes and comments on campaign posts (only Facebook, Instagram, and LinkedIn, if applicable).',
  tooltip_posts_sent: 'Total posts published in the campaign across all social networks.',
  total_engagement: 'Total engagement',
  total_views: 'Total views',
  top_posts_title: 'Top Posts',
  videos_title: 'Videos',
  videos_text_1: 'Show lifetime',
  videos_text_2: 'of published videos within the selected period.',
  video_views: 'Video Views',
  we_couldnt_create_your_report: "We couldn't create your report.",
  youtube_sidebar_link: 'YouTube',
};
