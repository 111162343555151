export const ReportColours = {
  campaigns: '#4d36ff',
};

export const ReportWidth = 1694;

export enum ReportTypes {
  CAMPAIGN_REPORT_TYPE = 'campaign',
}

export type ReportsList = {
  name: string;
  id: number;
  thumbnailUrl?: string;
};
