import styled, { css } from 'styled-components';

export const Container = styled.div<{ $hasChildren: boolean; $shrink: boolean }>`
  ${({ theme: { borderRadius, colors }, $hasChildren, $shrink }) => css`
    align-items: center;
    border-radius: ${borderRadius.sm};
    box-shadow: 0 0 0 1px ${colors.brandDarkAlpha20};
    display: flex;
    height: 2.5rem;
    min-width: ${$shrink ? 'unset' : $hasChildren ? '12rem' : '11rem'};
    position: relative;
  `}
`;

export const ChildrenBox = styled.div`
  z-index: -1;
`;

export const Data = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 0 1rem;
  user-select: none;
`;

export const IconBox = styled.div`
  align-items: center;
  aspect-ratio: 1 / 1;
  display: flex;
  height: 100%;
  justify-content: center;
  user-select: none;

  .icon {
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const Sel = styled.select`
  appearance: none;
  background: transparent;
  border: 0;
  color: transparent;
  cursor: pointer;
  font-family: 'IBM Plex Sans', sans-serif;
  height: 100%;
  left: 0;
  padding: 0.2rem 1rem 0.2rem 1rem;
  position: absolute;
  top: 0;
  width: 100%;
  user-select: none;
  z-index: 1;
`;

export const Option = styled.option`
  ${({ theme: { colors } }) => css`
    color: ${colors.brandDark};
  `}
`;
