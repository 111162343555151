import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';

export const FullWidthBox = styled(Box)`
  box-sizing: border-box;
  width: 100%;
`;

export const PaginationContainer = styled(Box)`
  width: 100%;
`;

export const ModuleErrorContainer = styled(Box)`
  background-color: #000000;
  border-radius: 0.375rem;
  box-sizing: border-box;
  color: #ffffff;
  flex-direction: column;
  flex-grow: 1;
  font-weight: bold;
  justify-content: center;
  opacity: 0.5;
  padding: 1.875rem 1.875rem 2.5rem 1.875rem;
  width: inherit;

  div {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    min-height: 10rem;
    width: 100%;
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 100%;
`;

export const TrendArrow = styled.span<{ $isPositive: boolean }>`
  ${({ theme: { colors }, $isPositive }) => css`
    position: relative;
    margin-right: 0.3rem;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    ${$isPositive &&
    css`
      &::before {
        background-color: ${colors.semanticSuccessShade50};
      }
    `}
    ${!$isPositive &&
    css`
      &::before {
        background-color: ${colors.semanticDangerShade20};
      }
    `}
    svg {
      width: 20px;
      height: 20px;
      display: block;
      position: relative;
    }
  `}
`;
