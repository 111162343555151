import { EVENTS, publish } from '@sendible/event-manager';
import { advancedLocalStorage, errorUtils, localStorageConsts } from '@sendible/common';

export * from './BridgeContext';

export const COMPOSE_TABS = {
  MESSAGE: 'message',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  GOOGLE_MY_BUSINESS_LOCATION: 'googlemybusinesslocation',
  LINKEDIN: 'linkedin',
  PINTEREST: 'pinterest',
  TIKTOK: 'tiktok',
  INSTAGRAM: 'instagram',
  GROUP: 'group',
  OTHER: 'other',
} as const;

export type composeTabs = (typeof COMPOSE_TABS)[keyof typeof COMPOSE_TABS];

export type AttachedMedias = {
  [key in composeTabs]: number[];
};

export interface ComposeTabInfo {
  isCustomised: boolean;
}

export type composeTabsInfo = {
  [key in composeTabs]: ComposeTabInfo;
};

export interface CountInfo {
  scheduledCount?: number;
  bouncedCount?: number | string;
  taskCount?: number;
  scheduledTeamCount?: number;
}

export interface ComposeStore {
  activeComposeBoxTab: composeTabs;
  isComposeBoxOpen: boolean;
  isMediaAttacherOpen: boolean;
  attachedMedias?: AttachedMedias;
  presentComposeBoxTabsInfo?: composeTabsInfo;
}

export interface UserStore {
  accessToken: string;
  userId: number;
  username: string;
  email: string;
  language: string;
  timezone: number;
  featureTags: object;
  isWhiteLabel: boolean;
  canNavigate: boolean;
  isSwitchedUser: boolean;
  countLabels?: CountInfo;
}

export interface AppStore {
  isCreativeEditorOpen: { url: string | null; serviceSet: null | string };
}

export interface SharedStateType {
  compose: ComposeStore;
  user: UserStore;
  app: AppStore;
}

export type BridgeContextType = [SharedStateType, React.Dispatch<React.SetStateAction<SharedStateType>>];

function getSharedState() {
  return advancedLocalStorage.getItem(localStorageConsts.sharedStateKey);
}

function setItem(value: SharedStateType) {
  advancedLocalStorage.setItem(localStorageConsts.sharedStateKey, value);
  publish(EVENTS.LOCAL_STORAGE_CHANGED, localStorageConsts.sharedStateKey);
}

function updateSpecificKey(store: string, key: string, value: unknown) {
  const existingBridgeStateValue = advancedLocalStorage.getItem(localStorageConsts.sharedStateKey);
  const newBridgeStateValue = {
    ...existingBridgeStateValue,
  };

  if (!newBridgeStateValue[store]) {
    return;
  }

  try {
    newBridgeStateValue[store][key] = value;

    setItem(newBridgeStateValue);
  } catch (error: unknown) {
    errorUtils.sendErrorDirectlyToOnerror(error as string);
  }
}

export default {
  getSharedState,
  setItem,
  updateSpecificKey,
};
