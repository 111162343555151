import { useRef, useMemo } from 'react';
import { Box, Icon, Spinner, Text } from '@sendible/design-system/src';
import { useTranslation } from 'react-i18next';
import { useReportsModel } from '@sendible/frontendv2/src/models/reports';
import {
  Container,
  LargeStat,
  StatRow,
  StatsContainer,
  StatsTable,
  TopMetrics,
  $smallScreenWidth,
  LargeStatContainer,
  TrendContainer,
  ChartContainer,
} from './index.styles';
import { Chart } from './components/Chart';
import { chartDummyData } from './mocks';
import { Warning } from './components/Warning';
import { TrendArrow } from '../index.styles';
import { useContainerDimensions, useFormattedDates } from '../../hooks';

export const Audience = ({ dateRange }: { dateRange: ReportDateRange }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { dimensions, isSmallScreen } = useContainerDimensions(containerRef, $smallScreenWidth, 0.6, 0.625);
  const { t } = useTranslation('reports');
  const { useGetTikTokAudience } = useReportsModel();
  const { isLoading, error, data = {} } = useGetTikTokAudience();
  const { startDateFriendly, endDateFriendly } = useFormattedDates(dateRange);

  const translations = useMemo(
    () => ({
      metricsText: t('metrics'),
      profileViewsText: t('profile_views'),
      netFollowersText: t('net_followers'),
      followersText: t('followers'),
      netFollowersGrowthText: t('net_followers_growth'),
      comparedToPreviousPeriodText: t('compared_to_previous_period'),
      needs_more_data: t('needs_more_data'),
      audience_error: t('audience_error'),
    }),
    [t]
  );

  if (isLoading) {
    return (
      <Box
        fullWidth
        contentAlignment="center"
        contentJustify="center"
        padding={['s48', 's0', 's48', 's0']}
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  const noData = !!(error || !data?.audience);

  const {
    audience: {
      timeSeriesData,
      totals: { profileViews, followers, netFollowers },
    },
  } = noData ? chartDummyData : data;

  const showWarning = noData || followers.current < 100;
  const netFollowersIsPositive = netFollowers.difference > 0;

  return (
    <>
      {showWarning && (
        <Warning
          height={dimensions.height}
          width={dimensions.width}
          text={error ? translations.audience_error : translations.needs_more_data}
          type={error ? 'error' : 'info'}
        />
      )}
      <Container
        ref={containerRef}
        $isHidden={showWarning}
      >
        <Box
          horizontalGap="s8"
          orientation="row"
        >
          <ChartContainer
            $height={dimensions.height}
            $width={dimensions.width}
          >
            <Chart
              timeSeriesData={timeSeriesData}
              isSmallScreen={isSmallScreen}
            />
          </ChartContainer>
          {!showWarning && (
            <StatsContainer
              orientation="column"
              contentJustify="space-between"
            >
              <TopMetrics orientation="column">
                <h3>{translations.metricsText}</h3>
                <StatsTable>
                  <StatRow contentJustify="space-between">
                    <div>{translations.profileViewsText}</div>
                    <div>{profileViews.current}</div>
                  </StatRow>
                  <StatRow contentJustify="space-between">
                    <div>{translations.followersText}</div>
                    <div>{followers.current}</div>
                  </StatRow>
                  <StatRow contentJustify="space-between">
                    <div>{translations.netFollowersText}</div>
                    <div>{netFollowers.current}</div>
                  </StatRow>
                </StatsTable>
              </TopMetrics>
              <LargeStatContainer
                orientation="column"
                alignSelf="flex-end"
                contentAlignment="flex-end"
              >
                <h3>{translations.netFollowersGrowthText}</h3>
                <LargeStat>
                  {netFollowersIsPositive && <>+</>}
                  {netFollowers.difference}
                </LargeStat>
                <TrendContainer>
                  <TrendArrow $isPositive={netFollowersIsPositive}>
                    <Icon
                      name={netFollowersIsPositive ? 'arrow_up_right' : 'arrow_down_left'}
                      color="brandLight"
                    />
                  </TrendArrow>
                  <Text variation="body_16">
                    +{netFollowers.difference} {translations.comparedToPreviousPeriodText}
                  </Text>
                </TrendContainer>
                <Text
                  variation="body_16"
                  color="brandDarkTint30"
                >
                  vs {startDateFriendly} - {endDateFriendly}
                </Text>
              </LargeStatContainer>
            </StatsContainer>
          )}
        </Box>
      </Container>
    </>
  );
};
