import { Select } from '@sendible/design-system';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useReportsModel } from '@sendible/frontendv2/src/models/reports';
import { Container, CustomText, MotionButton, PaginationSection, SectionOptions, Slider, SliderContainer } from './index.styles';
import { TikTokVideoCard } from './components/TikTokVideoCard';

interface VideosProps {
  currentProfile: TikTokAccountType;
}

const filterOptions: string[] = ['Engagement', 'Watch metrics', 'Sources'];

export const Videos = ({ currentProfile }: VideosProps) => {
  const [filter, setFilter] = useState<string>(filterOptions[0]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState(1);
  const sliderRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation(['reports', 'common_terms']);
  const { useGetTikTokVideos } = useReportsModel();
  const { data: videosData } = useGetTikTokVideos();
  const { videos } = videosData.result;

  const gapBetweenMediaCards = 32;
  const mediaCardWidth = 240;
  const offset = (sliderRef.current?.offsetParent as HTMLElement)?.offsetWidth ? (sliderRef.current?.offsetParent as HTMLElement)?.offsetWidth : 0;
  const totalVideoSpace = mediaCardWidth + gapBetweenMediaCards;
  const videosPerPage = offset / totalVideoSpace;

  useEffect(() => setPages(videos.length - videosPerPage), [offset]);

  return (
    <Container>
      <SectionOptions>
        <CustomText variation="body_14">{t('videos_text_1')}</CustomText>
        <Select
          onSelect={(option) => setFilter(option)}
          options={filterOptions}
          selectedOption={filter}
        />
        <CustomText variation="body_14">{t('videos_text_2')}</CustomText>
      </SectionOptions>
      <SliderContainer>
        <AnimatePresence>
          {page > 0 ? (
            <MotionButton
              key="prev-button"
              className="attach-prev nav-button"
              icon="arrow_left"
              onClick={() =>
                setPage((prev) => {
                  if (prev === 0) return prev;

                  return prev - 1;
                })
              }
              size={16}
              initial={{ x: '-5rem' }}
              animate={{ x: 0 }}
              exit={{ x: '-5rem' }}
            />
          ) : null}
          {page < pages ? (
            <MotionButton
              key="next-button"
              className="attach-next nav-button"
              icon="arrow_right"
              onClick={() =>
                setPage((prev) => {
                  if (prev >= pages) return prev;

                  return prev + 1;
                })
              }
              size={16}
              initial={{ x: '5rem' }}
              animate={{ x: 0 }}
              exit={{ x: '5rem' }}
            />
          ) : null}
        </AnimatePresence>
        <Slider
          ref={sliderRef}
          animate={sliderRef.current ? { x: 0 * page - page * totalVideoSpace } : {}}
          transition={{ ease: 'easeInOut' }}
        >
          {videos.map((video) => (
            <TikTokVideoCard
              caption="🌍✨ Navigating the Wild World of Social Media! ✨📱From viral trends to influencer hacks, join me as I explore how social media shapes our lives! 🎉📈 Let's dive into the good, the bad, and the hilarious moments! 😂💖"
              engagement={{
                likes: 120,
                comments: 98,
                shares: 20,
              }}
              key={video.videoId}
              postedDate="2024-09-22T11:00:00"
              profileAvatar={currentProfile.avatar || ''}
              profileName={currentProfile.username || ''}
              shareUrl="#"
              thumbnailUrl="https://images.pexels.com/photos/1324995/pexels-photo-1324995.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              userName={currentProfile.username || ''}
              videoId="7335477203552981004"
              views={189}
            />
          ))}
        </Slider>
      </SliderContainer>
      <PaginationSection>
        <CustomText variation="body_14">
          {page + 1} {t('of', { ns: 'common_terms' })} {Math.ceil(pages + 1)} {t(pages > 1 ? 'pages' : 'page', { ns: 'common_terms' })}
        </CustomText>
      </PaginationSection>
    </Container>
  );
};
