/* eslint-disable @typescript-eslint/no-explicit-any */
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Heading, LAPTOP_MAX_SIZE, useWindowSize } from '@sendible/design-system';
import { useTranslation } from 'react-i18next';
import { getLuminance } from 'polished';
import { makeNumbersReadFriendly } from '@sendible/frontendv2/src/utils';
import { getChartLeftMargin } from '@sendible/frontendv2/src/pages/Reports/helpers';
import { ChartContainer, Content } from '../../index.styles';
import { Summary } from '../../..';

interface InteractionsByDayProps {
  bestEngagingDay: TikTokVideoBestDayPerformanceType;
  data: BarDatum[];
  indexBy: 'day';
}

export const InteractionsByDayChart = ({ bestEngagingDay, data, indexBy }: InteractionsByDayProps) => {
  const { t } = useTranslation('reports');
  const { width } = useWindowSize();

  const isSmallScreen = width < LAPTOP_MAX_SIZE;

  const mappedDataNumbers = data
    .map((d) => Object.values(d).flat())
    .flat()
    .filter((o) => typeof o === 'number') as any;

  const biggestValue = Math.max(...mappedDataNumbers);
  const smallestValue = Math.min(...mappedDataNumbers);
  const marginLeft = getChartLeftMargin(biggestValue, smallestValue);

  const summaryData = [
    {
      label: t('date', { ns: 'common_terms' }),
      value: bestEngagingDay.date,
    },
    {
      label: t('total_engagement'),
      value: bestEngagingDay.current,
    },
    {
      label: t('previous_period'),
      value: bestEngagingDay.previous,
    },
    {
      label: t('growth'),
      value: `+${bestEngagingDay.difference} (${bestEngagingDay.percentage}%)`,
    },
  ];

  return (
    <Content>
      <Heading
        variation="component-title_14"
        semantic="h4"
      >
        {t('performance_chart2_title')}
      </Heading>
      <ChartContainer>
        <ResponsiveBar
          data={data}
          keys={['likes', 'comments', 'shares']}
          indexBy={indexBy}
          margin={{
            top: isSmallScreen ? 50 : 20,
            right: isSmallScreen ? 24 : 120,
            bottom: 24,
            left: marginLeft,
          }}
          padding={0.3}
          valueFormat={(v) => makeNumbersReadFriendly(v, 2)}
          colors={(bar) => {
            if (bar.id === 'likes') return '#000';
            if (bar.id === 'comments') return '#0075DB';
            if (bar.id === 'shares') return '#E10543';

            return '#CCC';
          }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            format: (v) => makeNumbersReadFriendly(v),
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={(bar) => (getLuminance(bar.color) > 0.5 ? '#000000' : '#FFFFFF')}
          legends={
            !isSmallScreen
              ? [
                  {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                  },
                ]
              : [
                  {
                    dataFrom: 'keys',
                    anchor: 'top-left',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: -32,
                    itemsSpacing: 2,
                    itemDirection: 'left-to-right',
                    itemWidth: 100,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 12,
                    symbolShape: 'circle',
                  },
                ]
          }
          animate
          motionConfig={{ damping: 15 }}
        />
      </ChartContainer>
      <Summary
        title={t('performance_best_engaging_day')}
        items={summaryData}
      />
    </Content>
  );
};
