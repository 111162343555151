import { IconBox, Thumb, ThumbBox } from './index.styles';
import { IconNames, SocialColors } from '../../../theme';
import { Icon } from '../../atoms/Icon';
import { Select, SelectProps } from '../Select';

export const socialMedias = ['facebook', 'googleMyBusiness', 'instagram', 'linkedin', 'pinterest', 'tiktok', 'twitter', 'youtube'] as const;

export interface SocialIcon {
  background: SocialColors;
  icon: IconNames;
}

export interface SocialSelectProps extends Omit<SelectProps, 'shrinkOnMobile'> {
  /**
   * Thumbnail link url. Required.
   */
  thumbnailUrl?: string;
  /**
   * Social media name. Required.
   */
  socialMedia?: (typeof socialMedias)[number];
}

export const SocialSelect = ({
  extraText,
  className,
  customClass,
  id,
  onSelect,
  options,
  selectedOption,
  socialMedia,
  thumbnailUrl,
  testId,
}: SocialSelectProps) => {
  const getSocialIcon = (): SocialIcon | undefined => {
    if (socialMedia === 'facebook') return { background: 'socialFacebook', icon: 'facebook' };
    if (socialMedia === 'googleMyBusiness') return { background: 'socialGoogleMyBusiness', icon: 'google_my_business' };
    if (socialMedia === 'instagram') return { background: 'socialInstagram', icon: 'instagram' };
    if (socialMedia === 'linkedin') return { background: 'socialLinkedin', icon: 'linkedin' };
    if (socialMedia === 'pinterest') return { background: 'socialPinterest', icon: 'pinterest' };
    if (socialMedia === 'tiktok') return { background: 'socialTiktok', icon: 'tiktok' };
    if (socialMedia === 'twitter') return { background: 'socialTwitter', icon: 'twitter' };
    if (socialMedia === 'youtube') return { background: 'socialYoutube', icon: 'youtube' };
  };

  const icon = getSocialIcon();

  return (
    <Select
      className={className}
      customClass={customClass}
      id={id}
      extraText={extraText}
      onSelect={(option) => onSelect(option)}
      options={options}
      selectedOption={selectedOption}
      shrinkOnMobile
      testId={testId}
    >
      {thumbnailUrl && icon && (
        <ThumbBox data-testid={`${testId}-imagebox`}>
          {icon && (
            <IconBox
              data-testid={`${testId}-iconbox`}
              $color={icon.background}
            >
              <Icon
                color="brandLight"
                name={icon.icon}
                useStroke={false}
              />
            </IconBox>
          )}
          <Thumb
            alt=""
            src={thumbnailUrl}
            data-testid={`${testId}-thumbnail`}
          />
        </ThumbBox>
      )}
    </Select>
  );
};
