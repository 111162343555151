export const overviewAPI = {
  profileViews: {
    current: {
      value: 12000,
    },
    previous: {
      value: 12120,
      difference: -120,
      percentage: -2.0,
    },
  },
  followers: {
    current: {
      value: 2300,
    },
    previous: {
      value: 2298,
      difference: 2,
      percentage: 0.4,
    },
  },
  videoViews: {
    current: {
      value: 769,
    },
    previous: {
      value: 771,
      difference: -2,
      percentage: -0.26,
    },
  },
  interactions: {
    current: {
      value: 0,
    },
    previous: {
      value: 0,
      difference: 0,
      percentage: 0.0,
    },
  },
  profileImage: 'https://picsum.photos/200/300',
};
export const audienceAPI = [
  {
    data: {
      audience: {
        totals: {
          profileViews: {
            current: 1000,
            previous: 900,
            difference: 100,
            percentage: 11.1,
          },
          followers: {
            current: 300,
            previous: 280,
            difference: 20,
            percentage: 7.1,
          },
          netFollowers: {
            current: 578,
            previous: 421,
            difference: 157,
            percentage: 37.3,
          },
        },
        timeSeriesData: [
          {
            date: '2024-09-16',
            profileViews: 50,
            followers: 20,
            netFollowers: 5,
          },
          {
            date: '2024-09-17',
            profileViews: 55,
            followers: 22,
            netFollowers: 6,
          },
          {
            date: '2024-09-18',
            profileViews: 60,
            followers: 25,
            netFollowers: 10,
          },
          {
            date: '2024-09-19',
            profileViews: 65,
            followers: 30,
            netFollowers: 15,
          },
          {
            date: '2024-09-20',
            profileViews: 70,
            followers: 32,
            netFollowers: 20,
          },
          {
            date: '2024-09-21',
            profileViews: 68,
            followers: 28,
            netFollowers: 18,
          },
          {
            date: '2024-09-22',
            profileViews: 72,
            followers: 35,
            netFollowers: 15,
          },
        ],
      },
    },
  },
];
