import { Box } from '@sendible/design-system/src';
import styled, { css } from 'styled-components';
import { HeaderContainer } from '../components/TikTok/Header/index.styles';
import { PaginationContainer } from '../components/index.styles';
import { TopPostsOrderContainer } from '../components/TopPosts/index.styles';
import { ReportWidth } from '../consts';

export const ReportContainer = styled.div`
  position: relative;
  width: 100%;
  background: #f1f0f8;

  &.print {
    left: 0;
    position: absolute;
    top: 0;
    width: ${ReportWidth}px;
  }

  &.print ${HeaderContainer} {
    display: none !important;
  }

  &.print ${PaginationContainer} {
    display: none !important;
  }

  &.print ${TopPostsOrderContainer} {
    display: none !important;
  }
`;

export const ReportLoadingOverlay = styled.div`
  align-items: center;
  background: white;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: sticky;
  width: inherit;
  z-index: 9999;
`;

export const ReportMessageContainer = styled(Box)`
  box-sizing: border-box;
  justify-content: center;
  padding: 3rem;
  width: 100%;
`;

export const NoReportsContainer = styled(Box)`
  width: 100%;
  flex-direction: column;
  padding: 14px 20px;
`;

export const NoReportsErrorContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 10rem;
  gap: 2rem;

  svg {
    width: 3rem;
    height: 3rem;
  }
`;

export const Sections = styled.div`
  ${({ theme: { colors } }) => css`
    background: ${colors.brandLight};
    display: flex;
    flex-direction: column;
    gap: 5.5rem;
    padding: 2rem 0;
  `}
`;

export const Section = styled.section``;

export const SectionHeader = styled.div`
  margin-bottom: 1rem;
  padding: 0 2rem;
`;
