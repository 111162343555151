import styled, { css } from 'styled-components';
import { Button } from '@sendible/design-system';
import { motion } from 'framer-motion';

export const Container = styled.div`
  ${({ theme: { colors } }) => css`
    align-items: center;
    display: flex;
    margin-left: -1rem;
    padding: 0.25rem 0 0.25rem 1rem;
    position: relative;
    overflow-x: hidden;
    width: calc(100% + 1rem);

    &::-webkit-scrollbar {
      display: none;
    }

    .nav-button {
      position: absolute;
      background: ${colors.brandLight};
      box-shadow: 0px 1px 18px 0px ${colors.brandDarkAlpha10}, 0px 6px 10px 0px ${colors.brandDarkAlpha15},
        0px 3px 5px -1px ${colors.brandDarkAlpha20};
      z-index: 100;

      &:hover {
        background: ${colors.brandLight};
        transform: scale(1.1);
      }
    }

    .attach-prev {
      left: 1rem;
    }

    .attach-next {
      right: 1rem;
    }
  `}
`;

export const MotionButton = styled(motion(Button))``;

export const Slider = styled(motion.div)`
  ${({ theme: { colors } }) => css`
    align-items: center;
    display: flex;
    gap: 1rem;

    .skeleton {
      height: 6.5rem;
      width: 6.5rem;
    }
  `}
`;

export const MediaSlot = styled.div`
  position: relative;
  width: 6.5rem;
  height: 6.5rem;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
`;
