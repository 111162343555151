import { Heading, Text } from '@sendible/design-system';
import { Container, Item, ItemList } from './index.styles';

interface SummaryItem {
  label: string;
  value: string | number;
}

interface SummaryProps {
  title: string;
  items: SummaryItem[];
}

export const Summary = ({ items, title }: SummaryProps) => (
  <Container>
    <Heading
      semantic="h3"
      variation="component-title_16"
    >
      {title}
    </Heading>
    <ItemList>
      {items.map(({ label, value }) => (
        <Item key={`${title}-${label}`}>
          <Text variation="body_14">{label}</Text>
          <Text
            variation="body_14"
            color="brandDarkTint30"
          >
            {value}
          </Text>
        </Item>
      ))}
    </ItemList>
  </Container>
);
