import { Icon, Text } from '@sendible/design-system/src';
import { WarningContainer, WarningTextContainer } from '../index.styles';

type WarningProps = { height: string; width: string; text: string; type: 'error' | 'info' };

export const Warning = ({ height, width, text, type = 'info' }: WarningProps) => {
  let icon = <Icon name="info" />;

  if (type === 'error') {
    icon = (
      <Icon
        name="warning"
        color="semanticDangerShade20"
      />
    );
  }

  return (
    <WarningContainer
      fullWidth
      contentAlignment="center"
      contentJustify="center"
    >
      <WarningTextContainer>
        {icon}
        &nbsp;<Text>{text}</Text>
      </WarningTextContainer>
    </WarningContainer>
  );
};
