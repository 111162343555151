import { useState, useRef } from 'react';
import { Button, Icon, ComposeDropDown, Tooltip } from '@sendible/design-system/src';
import { BackgroundFiller, Container, Image, MediaBadge } from './index.styles';

interface DropdownAction {
  action: string;
  click?: () => void;
  isHighlighted?: boolean;
  label: string;
}

export interface ComposeMediaCardProps extends Omit<Component, 'id'> {
  ariaMediaCardLabel?: string;
  mediaType?: MediaType['type'];
  orderNumber?: string;
  isGif?: boolean;
  dropdownButtonClick: (option: DropdownAction['action']) => void;
  videoDuration?: number;
  id: number;
  open?: (id: number) => void;
  tabIndex?: number;
  thumbUrl?: string;
}

const videoDropdownOptions = [
  {
    action: 'edit',
    label: 'Edit',
    icon: 'brush',
    badge: 'NEW',
  },
  {
    action: 'details',
    label: 'Details',
    icon: 'video',
  },
  {
    action: 'replace',
    label: 'Replace',
    icon: 'refresh_cw',
  },
  {
    action: 'remove',
    label: 'Remove',
    isHighlighted: true,
    icon: 'minus_circle',
  },
];

const imageDropdownOptions = [
  {
    action: 'edit',
    label: 'Edit',
    icon: 'brush',
    badge: 'NEW',
  },
  {
    action: 'alttext',
    label: 'Alt text',
    icon: 'eye_off',
  },
  {
    action: 'tag',
    label: 'Tag',
    icon: 'tag',
  },
  {
    action: 'replace',
    label: 'Replace',
    icon: 'refresh_cw',
  },
  {
    action: 'remove',
    label: 'Remove',
    isHighlighted: true,
    icon: 'minus_circle',
  },
];

const pdfDropdownOptions = [
  {
    action: 'replace',
    label: 'Replace',
    icon: 'refresh_cw',
  },
  {
    action: 'remove',
    label: 'Remove',
    isHighlighted: true,
    icon: 'minus_circle',
  },
];

export const ComposeMediaCard = (props: ComposeMediaCardProps) => {
  const {
    ariaMediaCardLabel = '',
    className = '',
    customClass = '',
    dropdownButtonClick,
    orderNumber = '',
    videoDuration = 0,
    isGif = false,
    mediaType = 'Image',
    id,
    open,
    tabIndex = -1,
    testId = 'composemediacard',
    thumbUrl,
  } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef(null);
  const typeLC = mediaType.toLowerCase();

  const getTime = () => {
    const mins = Math.floor(videoDuration / 60000);
    const secs = ((videoDuration % 60000) / 1000).toFixed(0);

    return `${mins}:${Number(secs) < 10 ? '0' : ''}${secs}`;
  };

  const dropdownActions: { [key: string]: { action: string; label: string }[] } = {
    image: imageDropdownOptions,
    video: videoDropdownOptions,
    pdf: pdfDropdownOptions,
  };

  return (
    <Container
      className={`mediacard ${customClass} ${className}`}
      data-testid={testId}
    >
      <Image
        tabIndex={tabIndex}
        data-testid={`${testId}-image`}
        aria-label={ariaMediaCardLabel}
      >
        <>
          <BackgroundFiller
            data-testid={`${testId}-button`}
            id={id.toString()}
            onClick={() => open && open(id)}
            tabIndex={-1}
          />

          {thumbUrl && (
            <img
              alt=""
              src={thumbUrl}
            />
          )}

          {typeLC.includes('image') && (
            <>
              <Icon
                customClass="placeholder"
                name="image"
              />
              {isGif ? <MediaBadge position="bottom_left">GIF</MediaBadge> : ''}
            </>
          )}
          {typeLC.includes('video') && (
            <>
              <Icon
                customClass="placeholder"
                name="video"
              />
              {videoDuration ? <MediaBadge position="bottom_left">{getTime()}</MediaBadge> : ''}
            </>
          )}
          {typeLC.includes('pdf') && (
            <>
              <Icon
                customClass="placeholder"
                name="download_pdf"
              />
              <MediaBadge position="bottom_left">PDF</MediaBadge>
            </>
          )}
          {orderNumber ? <MediaBadge position="top_left">{orderNumber}</MediaBadge> : ''}
        </>
      </Image>
      <ComposeDropDown
        options={dropdownActions[typeLC] || []}
        placement="bottom-start"
        select={(option: string) => dropdownButtonClick(option)}
        visibilityChange={(state: boolean) => !state && setIsDropdownOpen(false)}
        width="12rem"
      >
        <Tooltip content={<>Options</>}>
          <Button
            aria-controls="menu"
            aria-expanded={isDropdownOpen}
            aria-haspopup="true"
            onClick={() => {
              setIsDropdownOpen(isDropdownOpen);
            }}
            customClass={isDropdownOpen ? 'show-dropdown-button' : ''}
            icon="chevron_down"
            variation="fill"
            ref={ref}
            size={12}
            data-testid={`${testId}-dropdown-btn`}
          />
        </Tooltip>
      </ComposeDropDown>
    </Container>
  );
};
