import styled, { css } from 'styled-components';

interface MediaBadgePropsInterface {
  position?: 'top_left' | 'bottom_left';
}

export const Container = styled.div`
  ${({
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    position: relative;
    width: 100%;

    .dropdown-link {
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
    }

    .button {
      transition: all 0.1s ease-in-out;
      opacity: 0;
      visibility: hidden;
      z-index: 1;
    }

    &:hover {
      .button {
        margin-right: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    .show-dropdown-button {
      opacity: 1;
      visibility: visible;

      &:hover {
        .button {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    @media all and (${smallDevices.min}) and (${smallDevices.max}) {
      .button {
        display: flex;
      }
    }
  `}
`;

export const Image = styled.div`
  ${({
    theme: { borderRadius, colors, motions, motionSpeeds },
    theme: {
      breakPoints: { smallDevices },
    },
  }) => css`
    align-items: center;
    aspect-ratio: 1 / 1;
    background: ${colors.brandDarkAlpha10};
    border-radius: ${borderRadius.sm};
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &::before {
      border-radius: inherit;
      box-shadow: inset 0 0 0 1px ${colors.brandDarkAlpha20};
      content: '';
      display: block;
      height: 100%;
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      user-select: none;
      width: 100%;
      z-index: 1;
    }

    .placeholder {
      path {
        stroke: ${colors.brandDarkAlpha60};
      }
    }

    img {
      cursor: pointer;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      user-select: none;
    }
  `}
`;

export const BackgroundFiller = styled.button`
  background: transparent;
  border: 0;
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  z-index: 1;
`;

export const MediaBadge = styled.div<MediaBadgePropsInterface>`
  ${({ theme: { colors }, position = 'top_left' }) => css`
    font-family: 'IBM Plex Sans';
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem;
    letter-spacing: 0.3px;

    height: 1.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    background: ${colors.brandDarkAlpha90};
    position: absolute;
    left: 0.5rem;

    ${position === 'top_left' &&
    css`
      width: 1.125rem;
      top: 0.5rem;
      border-radius: 0.75rem;
    `}

    ${position === 'bottom_left' &&
    css`
      width: auto;
      bottom: 0.5rem;
      border-radius: 0.25rem;
      padding: 0 0.25rem;
    `}
    color: #ffffff;
    text-align: center;
  `}
`;
